import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import axios from 'axios';

export const UserProfileContext = createContext();

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getAddressStr = (address) => {
  if (address) {
    const suiteStr = address.suite || '';
    const streetStr = address.street || '';
    const suburbStr = address.suburb ? `${address.suburb} ` : '';
    const stateStr = address.state ? `${address.state} ` : '';
    const postcodeStr = address.postcode ? `${address.postcode} ` : '';
    const middleStr = `${suburbStr}${stateStr}${postcodeStr}`;
    const countryStr = address.country ? address.country.name : '';
    return `${suiteStr ? `${suiteStr}, ` : ''}${streetStr ? `${streetStr}, ` : ''}${middleStr ? `${middleStr.trim()}, ` : ''}${countryStr}`;
  }
  return '';
};

const getCompanyName = (companies) => {
  if (companies && companies.length > 0) {
    const found = companies.filter((company) => company.is_primary === false);
    if (found.length > 0) return found.name;
    return companies[0].name;
  }
  return undefined;
};

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState();
  const [userSignature, setUserSignature] = useState();
  const [userAvatar, setUserAvatar] = useState();

  const fetchUserAvatar = useCallback(async () => {
    try {
      const avatarRes = await axios.get(`${process.env.REACT_APP_API_LMI_RISKCOACH}user/avatar`, { responseType: 'blob' });
      const avatar = await getBase64(avatarRes.data);
      setUserAvatar(avatar);
      return Promise.resolve('User avatar has been successfully loaded');
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const fetchUserSignature = useCallback(async () => {
    try {
      const signatureRes = await axios.get(`${process.env.REACT_APP_API_LMI_RISKCOACH}user/signature`, { responseType: 'blob' });
      const signature = await getBase64(signatureRes.data);
      setUserSignature(signature);
      return Promise.resolve('User signature has been successfully loaded');
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const fetchUserProfile = useCallback(async () => {
    try {
      const urls = ['user/profile', 'user/companies'].map((endpoint) => `${process.env.REACT_APP_API_LMI_RISKCOACH}${endpoint}`);

      await Promise.all(urls.map((url) => axios.get(url))).then(async ([{ data: userProfileData }, { data: companies }]) => {
        const profile = {
          ...userProfileData,
          address: getAddressStr(userProfileData.address),
          company: getCompanyName(companies),
        };
        setUserProfile(profile);
      });
      return Promise.resolve('User profile has been successfully loaded');
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const updateUserSignature = useCallback(
    async (data) => {
      try {
        await axios.put(`${process.env.REACT_APP_API_LMI_RISKCOACH}user/signature`, data);
        await fetchUserSignature();
        return Promise.resolve('User signature has been successfully uploaded');
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [fetchUserSignature],
  );

  const value = useMemo(
    () => ({ userProfile, userSignature, userAvatar, updateUserSignature, fetchUserProfile, fetchUserAvatar, fetchUserSignature }),
    [userProfile, userSignature, userAvatar, updateUserSignature, fetchUserProfile, fetchUserAvatar, fetchUserSignature],
  );

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
};

export const useUserProfile = () => useContext(UserProfileContext);

export const options = {
  premiums: {
    reportType: [
      {
        label: 'renewal',
        value: 'renewal',
      },
      {
        label: 'quote',
        value: 'quote',
      },
    ],
    increaseReasonOptions: [
      {
        label: 'rate increases in line with current market conditions',
        value: 'rate increases in line with current market conditions',
      },
      { label: 'previous claims made on your policy', value: 'previous claims made on your policy' },
      { label: 'wages increased', value: 'wages increased' },
    ],
    decreaseReasonOptions: [
      { label: 'wages decreased', value: 'wages decreased' },
      {
        label: 'we have successfully negotiated a reduction in premiums',
        value: 'we have successfully negotiated a reduction in premiums',
      },
    ],
  },
  alternateQuotation: {
    alternateQuotationPremiumOptions: [
      { label: 'Allianz', value: 'Allianz' },
      { label: 'CGU', value: 'CGU' },
      { label: 'GIO', value: 'GIO' },
      { label: 'QBE', value: 'QBE' },
      { label: 'No alternative quotations sought', value: 'No alternative quotations sought' },
    ],
    reasonOptions: [
      { label: 'rate', value: 'rate' },
      { label: 'claims service', value: 'claims service' },
      { label: 'consistency/relationship', value: 'consistency/relationship' },
    ],
  },
  unisuredRisks: {
    recommendedCoversOptions: [
      { label: 'Pre-employment Checks', value: 'PreemploymentCheck' },
      { label: 'Work Health and Safety (WHS) Manuals and Incident Reporting', value: 'WHSIncidentReporting' },
      { label: 'Return to Work Plan', value: 'ReturnToWorkPlan' },
      { label: 'Common Due Dating', value: 'CommonDueDating' },
    ],
    itemsToConsiderOptions: [
      { label: 'Claims Timeline', value: 'ClaimsTimeline' },
      { label: 'Declaring Wages', value: 'DeclaringWages' },
      { label: 'Quarterly Payments', value: 'QuarterlyPayments' },
      { label: 'Contractors', value: 'Contractors' },
    ],
  },
};

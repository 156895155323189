/* eslint-disable no-nested-ternary */
import React from 'react';
import { Currency } from 'components';
import {
  Content,
  Heading,
  Page,
  PageTitle,
  Paragraph,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Star,
} from 'pages/Report/components/Styled';
import { renewalCoversOrder, renewalCovers } from 'pages/Question/MasterRenewal/data';
import { Premiums } from './Premiums';

export const newLineTextFormatter = (text) => (text ? text.split('\n').map((str) => <p>{str}</p>) : text);

const calcVariation = (num1, num2) => {
  const variation = num1 - num2;
  const variationPercent = `${Number.isNaN(variation) ? 'N/A' : `${((variation / num2) * 100).toFixed(2)}%`}`;

  return { variation, variationPercent };
};

export const OverallPremiums = ({ formName, reportState }) => {
  const date = new Date();
  const year = date.getFullYear();
  const { premiums, alternateQuotation, reportType } = reportState;
  const { selectedCommercialLinesCovers = [], selectedPersonalLineCovers = [] } = reportState.covers;

  const orderedForms = [
    ...selectedCommercialLinesCovers.map((cover) => ({ cover, order: renewalCoversOrder[cover] })),
    ...selectedPersonalLineCovers.map((cover) => ({ cover, order: renewalCoversOrder[cover] })),
  ]
    .sort((formA, formB) => formA.order - formB.order)
    .map((form) => form.cover);

  const allRenewalPremiums = orderedForms.map((cover) => {
    const renewals =
      premiums?.[cover]?.renewalOther?.length > 0
        ? premiums?.[cover]?.renewal
          ? [...premiums[cover].renewal, ...premiums[cover].renewalOther]
          : premiums[cover].renewalOther
        : premiums[cover].renewal;

    const lastYear =
      premiums?.[cover]?.lastYearOther?.length > 0
        ? premiums?.[cover]?.lastYear
          ? [...premiums[cover].lastYear, ...premiums[cover].lastYearOther]
          : premiums[cover].lastYearOther
        : premiums[cover].lastYear;

    return {
      cover,
      renewals,
      renewalSum: renewals.reduce((acc, premium) => acc + premium.premium, 0),
      lastYear,
      lastYearSum: reportType !== 'quote' && lastYear && lastYear.reduce((acc, premium) => acc + premium.premium, 0),
    };
  });

  const overallRenewals = {
    allRenewalSum: allRenewalPremiums.reduce((acc, cover) => acc + cover.renewalSum, 0),
    allLastYearSum: allRenewalPremiums.reduce((acc, cover) => acc + cover.lastYearSum, 0),
  };

  const parsedCoverPremiumsReportState = orderedForms.map((cover) => {
    const declineReason = alternateQuotation[cover]?.declineReason ? alternateQuotation[cover].declineReason : [];
    const otherDeclineReason = alternateQuotation[cover]?.declineOtherReason
      ? alternateQuotation[cover].declineOtherReason.map((d) => ({
          label: d.reason,
          value: d.reason,
          checked: d.checked,
        }))
      : [];
    const alternateQuotePremium =
      alternateQuotation[cover]?.premium &&
      alternateQuotation[cover].premium.map((p) => ({
        label: p.insurer || p.label,
        value: p.insurer || p.value,
        checked: true,
        amount: p.premium || p.amount,
      }));
    const decline =
      alternateQuotation[cover]?.decline &&
      alternateQuotation[cover].decline.map((d) => ({
        label: d.insurer,
        value: d.insurer,
        checked: true,
      }));

    return {
      cover,
      reportType,
      premiums: premiums[cover] && premiums[cover],
      alternateQuotation: {
        premium: alternateQuotePremium,
        reason: alternateQuotation[cover]?.reason,
        otherReason: alternateQuotation[cover]?.otherReason,
        decline,
        declineReason: [...declineReason, ...otherDeclineReason],
      },
    };
  });

  return (
    <>
      {orderedForms.length > 1 && (
        <Page dynamic formName={formName}>
          <PageTitle>Premium Summary</PageTitle>
          <Content>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell colSpan={2}>
                    <Heading level={2}>{reportType !== 'quote' ? 'Premiums' : 'Quoted Premiums'}</Heading>
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${year} - ${year + 1}`} Renewal Premium</TableCell>
                  <TableCell align="right">
                    <Currency value={overallRenewals.allRenewalSum} />
                  </TableCell>
                </TableRow>
                {reportType !== 'quote' && (
                  <>
                    <TableRow>
                      <TableCell>{`${year - 1} - ${year}`} Renewal Premium</TableCell>
                      <TableCell align="right">
                        <Currency value={overallRenewals.allLastYearSum} />
                        <Star />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Variation (%)</TableCell>
                      <TableCell align="right" bold>
                        {calcVariation(overallRenewals.allRenewalSum, overallRenewals.allLastYearSum).variationPercent}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Variation ($)</TableCell>
                      <TableCell align="right" bold>
                        <Currency value={calcVariation(overallRenewals.allRenewalSum, overallRenewals.allLastYearSum).variation} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        *includes any alterations made to the policy throughout the year
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>

            {premiums?.overallSummaryComments && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>
                      <Heading level={2}>Summary</Heading>
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell highlight>
                      <Paragraph>{newLineTextFormatter(premiums.overallSummaryComments)}</Paragraph>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {allRenewalPremiums.length > 1 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell compact>
                      <Heading level={5}>Class</Heading>
                    </TableHeadCell>
                    <TableHeadCell compact>
                      <Heading level={5}>Insurer</Heading>
                    </TableHeadCell>
                    <TableHeadCell compact>
                      <Heading level={5}>{`${year} - ${year + 1}`}</Heading>
                    </TableHeadCell>
                    {reportType !== 'quote' && (
                      <>
                        <TableHeadCell compact>
                          <Heading level={5}>Insurer</Heading>
                        </TableHeadCell>
                        <TableHeadCell compact>
                          <Heading level={5}>{`${year - 1} - ${year}`}</Heading>
                        </TableHeadCell>
                        <TableHeadCell compact>
                          <Heading level={5}>Variance (%)</Heading>
                        </TableHeadCell>
                        <TableHeadCell compact>
                          <Heading level={5}>Variance ($)</Heading>
                        </TableHeadCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRenewalPremiums.map((premium) => (
                    <TableRow align="center" key={premium.cover}>
                      <TableCell compact>{renewalCovers[premium.cover]}</TableCell>
                      <TableCell compact align="center">
                        {premium.renewals.map((renewal) => (
                          <div key={renewal.insurer}>{renewal.insurer}</div>
                        ))}
                      </TableCell>
                      <TableCell compact align="center">
                        {premium.renewals.map((renewal) => (
                          <div>
                            <Currency value={renewal.premium} />
                          </div>
                        ))}
                      </TableCell>
                      {reportType !== 'quote' && (
                        <>
                          <TableCell compact align="center">
                            {premium?.lastYear?.map((lastyear) => (
                              <div key={lastyear.insurer}>{lastyear.insurer}</div>
                            ))}
                          </TableCell>
                          <TableCell compact align="center">
                            {premium?.lastYear?.map((lastyear) => (
                              <div>
                                <Currency value={lastyear.premium} />
                              </div>
                            ))}
                          </TableCell>
                          <TableCell compact align="center">
                            {calcVariation(premium.renewalSum, premium.lastYearSum).variationPercent}
                          </TableCell>
                          <TableCell compact align="center">
                            <Currency value={calcVariation(premium.renewalSum, premium.lastYearSum).variation} />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell compact bold>
                      Total
                    </TableCell>
                    <TableCell compact align="center" />
                    <TableCell compact align="center" bold>
                      <Currency value={overallRenewals.allRenewalSum} />
                    </TableCell>
                    {reportType !== 'quote' && (
                      <>
                        <TableCell compact align="center" />
                        <TableCell compact align="center" bold>
                          <Currency value={overallRenewals.allLastYearSum} />
                        </TableCell>
                        <TableCell compact align="center" bold>
                          {calcVariation(overallRenewals.allRenewalSum, overallRenewals.allLastYearSum).variationPercent}
                        </TableCell>
                        <TableCell compact align="center" bold>
                          <Currency value={calcVariation(overallRenewals.allRenewalSum, overallRenewals.allLastYearSum).variation} />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Content>
        </Page>
      )}

      {!!parsedCoverPremiumsReportState &&
        parsedCoverPremiumsReportState.map((parsedReportState) => (
          <Premiums key={parsedReportState.cover} cover={parsedReportState.cover} formName={formName} reportState={parsedReportState} />
        ))}
    </>
  );
};

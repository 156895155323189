import React from 'react';
import { Button, message, Space, Upload } from 'antd';
import styled from 'styles';

const beforeUpload = (file) => {
  const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isImage) {
    message.error('Only files with the following extensions are allowed: png jpg jpeg.');
  }
  return isImage;
};

const Image = styled.img`
  width: ${({ width }) => width}px;
  height: auto;
`;

export const Signature = ({ size = 120, src, editable, onUpload }) => (
  <Space size={20}>
    {src && <Image src={src} width={size} />}
    {editable && (
      <Upload showUploadList={false} beforeUpload={beforeUpload} customRequest={onUpload}>
        <Button ghost type="primary" size="large">
          {src ? 'Update' : 'Upload'}
        </Button>
      </Upload>
    )}
  </Space>
);

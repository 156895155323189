export const NoAlternativeQuotationSought = 'No alternative quotations sought';

export const commercialLinesCovers = {
  BusinessPack: 'Business Pack',
  PortableProperty: 'Portable Property',
  MachineryBreakdown: 'Machinery Breakdown',
  ElectronicEquipment: 'Electronic Equipment',
  MarineTransit: 'Marine/Inland Transit',
  Liability: 'Liability',
  ProfessionalIndemnity: 'Professional Indemnity',
  ManagementLiability: 'Management Liability',
  Cyber: 'Cyber',
  CorporateTravel: 'Corporate Travel',
  CommercialMotor: 'Commercial Motor',
  WorkersCompensation: 'Workers Compensation',
  VoluntaryWorkers: 'Voluntary Workers',
  AssociationLiability: 'Association Liability',
  ContractWorks: 'Contract Works',
  IndustrialSpecialPlant: 'Industrial Special Plant',
  IndustrialSpecialRisks: 'Industrial Special Risks',
};

export const personalLinesCovers = {
  PleasureCraft: 'Pleasure Craft',
  Motorcycle: 'Motorcycle',
  Caravan: 'Caravan',
  MotorVehicle: 'Motor Vehicle',
  Landlords: 'Landlords',
  Householders: 'Householders',
};

export const renewalCovers = {
  ...commercialLinesCovers,
  ...personalLinesCovers,
};

export const businessPackCovers = {
  Building: 'Building',
  ContentsStock: 'Contents / Stock',
  Theft: 'Theft',
  Glass: 'Glass',
  Money: 'Money',
  Liability: 'Liability',
  ElectronicEquipment: 'Electronic Equipment',
  MachineryBreakdown: 'Machinery Breakdown',
  BusinessInterruption: 'Business Interruption',
  PortableProperty: 'Portable Property',
};

export const renewalCoversOrder = {
  BusinessPack: 1,
  PortableProperty: 2,
  MachineryBreakdown: 3,
  ElectronicEquipment: 4,
  MarineTransit: 5,
  Liability: 6,
  ProfessionalIndemnity: 7,
  ManagementLiability: 8,
  Cyber: 9,
  CorporateTravel: 10,
  CommercialMotor: 11,
  WorkersCompensation: 12,
  VoluntaryWorkers: 13,
  AssociationLiability: 14,
  ContractWorks: 15,
  IndustrialSpecialPlant: 16,
  IndustrialSpecialRisks: 17,
  PleasureCraft: 18,
  Motorcycle: 19,
  Caravan: 20,
  MotorVehicle: 21,
  Landlords: 22,
  Householders: 23,
};

const reasonOptions = [
  { label: 'not competitive', value: 'not competitive' },
  { label: 'limited cover', value: 'limited cover' },
  { label: 'imposed excess', value: 'imposed excess' },
  { label: 'endorsement conditions', value: 'endorsement conditions' },
  { label: 'imposed minimum premium', value: 'imposed minimum premium' },
];

const declineOptions = [
  { label: 'outside underwriting guidelines', value: 'outside underwriting guidelines' },
  { label: 'claims history', value: 'claims history' },
  { label: 'cyclone exposure', value: 'cyclone exposure' },
];

const alternateQuotationPremiumOptions = [];
const alternateQuotationDeclineOptions = [];

const personalLinesPremiumOptions = [
  { label: 'TIO', value: 'TIO' },
  { label: 'QBE', value: 'QBE' },
  { label: 'Millennium CGU', value: 'Millennium CGU' },
  { label: 'Blue Zebra', value: 'Blue Zebra' },
  { label: 'CGU', value: 'CGU' },
  { label: 'NM Insurance', value: 'NM Insurance' },
  { label: 'CIL', value: 'CIL' },
  { label: 'Allianz', value: 'Allianz' },
];

const personalLinesAltQuoteOptions = [
  { label: 'TIO', value: 'TIO' },
  { label: 'QBE', value: 'QBE' },
  { label: 'Millennium CGU', value: 'Millennium CGU' },
  { label: 'Blue Zebra', value: 'Blue Zebra' },
  { label: 'CGU', value: 'CGU' },
  { label: 'NM Insurance', value: 'NM Insurance' },
  { label: 'CIL', value: 'CIL' },
  { label: 'Allianz', value: 'Allianz' },
  { label: 'Other', value: 'Other' },
  { label: NoAlternativeQuotationSought, value: NoAlternativeQuotationSought },
];

export const options = {
  premiums: {
    personalLinesPremiumOptions,
    reportTypeOptions: [
      {
        label: 'renewal',
        value: 'renewal',
      },
      {
        label: 'quote',
        value: 'quote',
      },
    ],
    increaseReasonOptions: [
      {
        label: 'rate increases in line with current market conditions',
        value: 'rate increases in line with current market conditions',
      },
      {
        label: 'an increase in sums insured',
        value: 'an increase in sums insured',
      },
      {
        label: 'previous claims made on your policy',
        value: 'previous claims made on your policy',
      },
      {
        label: 'standard CPI indexing of sums insured',
        value: 'standard CPI indexing of sums insured',
      },
      {
        label: 'additional covers taken',
        value: 'additional covers taken',
      },
    ],
    decreaseReasonOptions: [
      {
        label: 'we have successfully negotiated a reduced premium with your insurer',
        value: 'we have successfully negotiated a reduced premium with your insurer',
      },
      {
        label: 'a decrease in sums insured',
        value: 'a decrease in sums insured',
      },
      {
        label: 'covers removed',
        value: 'covers removed',
      },
    ],
  },
  alternateQuotation: {
    BusinessPack: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    PortableProperty: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    MachineryBreakdown: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    ElectronicEquipment: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    MarineTransit: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Liability: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    ProfessionalIndemnity: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    ManagementLiability: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Cyber: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    CorporateTravel: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    CommercialMotor: {
      alternateQuotationPremiumOptions: [
        { label: 'TIO', value: 'TIO' },
        { label: 'GT', value: 'GT' },
        { label: 'CGU', value: 'CGU' },
        { label: 'QBE', value: 'QBE' },
        { label: 'Other', value: 'Other' },
        { label: NoAlternativeQuotationSought, value: NoAlternativeQuotationSought },
      ],
      reasonOptions,
      alternateQuotationDeclineOptions: [
        { label: 'TIO', value: 'TIO' },
        { label: 'GT', value: 'GT' },
        { label: 'Other', value: 'DeclineOther' },
      ],
      declineOptions: [
        ...declineOptions,
        { label: 'ongoing/active claims', value: 'ongoing/active claims' },
        { label: 'unrepaired damage', value: 'unrepaired damage' },
        { label: 'driver history', value: 'driver history' },
      ],
    },
    WorkersCompensation: {
      alternateQuotationPremiumOptions: [
        { label: 'Allianz', value: 'Allianz' },
        { label: 'CGU', value: 'CGU' },
        { label: 'GIO', value: 'GIO' },
        { label: 'QBE', value: 'QBE' },
        { label: NoAlternativeQuotationSought, value: NoAlternativeQuotationSought },
      ],
      reasonOptions: [
        ...reasonOptions,
        { label: 'rate', value: 'rate' },
        { label: 'claims service', value: 'claims service' },
        { label: 'consistency/relationship', value: 'consistency/relationship' },
      ],
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    VoluntaryWorkers: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    AssociationLiability: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    ContractWorks: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    IndustrialSpecialPlant: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    IndustrialSpecialRisks: {
      alternateQuotationPremiumOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    PleasureCraft: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Motorcycle: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Caravan: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    MotorVehicle: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Landlords: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
    Householders: {
      alternateQuotationPremiumOptions: personalLinesAltQuoteOptions,
      reasonOptions,
      alternateQuotationDeclineOptions,
      declineOptions,
    },
  },
  uninsuredRisks: {
    businessPackRecommendedCoverOptions: Object.entries(businessPackCovers).map(([key, value]) => ({ label: value, value: key })),
    recommendedCoversOptions: Object.entries(renewalCovers)
      .map(([key, value]) => ({ label: value, value: key }))
      .filter((cover) => !['BusinessPack', 'IndustrialSpecialPlant', 'IndustrialSpecialRisks'].includes(cover.value)),
    itemsToConsiderOptions: [
      { label: 'Contractual Requirements', value: 'ContractualRequirements' },
      { label: 'Cyclone Preparedness', value: 'CyclonePreparedness' },
      { label: 'Fire Safety Equipment', value: 'FireSafetyEquipment' },
      { label: 'Flood', value: 'Flood' },
      { label: 'Preventative Maintenance', value: 'PreventativeMaintenance' },
      { label: 'Sum Insured Reviews', value: 'SumInsuredReviews' },
      { label: 'Under Insurance', value: 'UnderInsurance' },
      { label: 'Subcontractors/Labour Hire', value: 'SubcontractorsLabourHire' },
      { label: 'Imports/Exports', value: 'ImportsExports' },
    ],
  },
};

export const personalLinesUninsuredRisksOptions = {
  PleasureCraft: {
    recommendedCoversOptions: [
      { label: 'Accessories/Modifications to the Pleasure Craft', value: 'AccessoriesModificationsPleasureCraft' },
      { label: 'Storage of Boat/Mooring', value: 'StorageOfBoatMooring' },
      { label: 'Water-skiing Liability and Aquaplaning Activities', value: 'Water-skiingLiabilityAndAquaplaningActivities' },
      { label: 'Racing and Competitive Use', value: 'RacingAndCompetitiveUse' },
    ],
    itemsToConsiderOptions: [
      { label: 'Maintenance', value: 'Maintenance' },
      { label: 'Duty of Disclosure', value: 'DutyOfDisclosure' },
    ],
  },
  Motorcycle: {
    recommendedCoversOptions: [
      { label: 'Accessories/Modifications to the Motorcycle', value: 'AccessoriesModificationsMotorcycle' },
      { label: 'Driver History', value: 'DriverHistory' },
      { label: 'Undeclared/Unnamed Riders', value: 'UndeclaredUnnamedRiders' },
      { label: 'Young/Inexperienced Riders', value: 'YoungInexperiencedRiders' },
    ],
    itemsToConsiderOptions: [{ label: 'Duty of Disclosure', value: 'DutyOfDisclosure' }],
  },
  Caravan: {
    recommendedCoversOptions: [
      { label: 'Accessories/Modifications to the Caravan', value: 'AccessoriesModificationsCaravan' },
      { label: 'Contents Insurance', value: 'ContentsInsurance' },
      { label: 'Storage of Caravan', value: 'StorageOfCaravan' },
    ],
    itemsToConsiderOptions: [
      { label: 'Maintenance', value: 'Maintenance' },
      { label: 'Duty of Disclosure', value: 'DutyOfDisclosure' },
    ],
  },
  MotorVehicle: {
    recommendedCoversOptions: [
      { label: 'Accessories/Modifications to the Vehicle ', value: 'AccessoriesModificationsVehicle' },
      { label: 'Driver History', value: 'DriverHistory' },
      { label: 'Undeclared/Unnamed Drivers', value: 'UndeclaredUnnamedDrivers' },
      { label: 'Young/Inexperienced Drivers', value: 'YoungInexperiencedDrivers' },
    ],
    itemsToConsiderOptions: [
      { label: 'Car Hire Following an Accident', value: 'CarHireFollowingAccident' },
      { label: 'Roadside Assistance (BZI Only)', value: 'RoadsideAssistanceBZIOnly' },
      { label: 'Duty of Disclosure', value: 'DutyOfDisclosure' },
      { label: 'Excess Information', value: 'ExcessInformation' },
    ],
  },
  Landlords: {
    recommendedCoversOptions: [
      { label: 'Contents Insurance', value: 'ContentsInsurance' },
      { label: 'Contents - Specified Contents, Special Contents and/or Valuables', value: 'ContentsSpecialValuables' },
      { label: 'Disaster Cover', value: 'DisasterCover' },
      { label: 'Rent Default and Theft by Tenant', value: 'RentDefaultTheftByTenant' },
    ],
    itemsToConsiderOptions: [
      { label: 'Building Sum Insured Calculator', value: 'BuildingSumInsuredCalculator' },
      { label: 'Circumstance Changes', value: 'CircumstanceChanges' },
      { label: 'Cyclone Preparedness', value: 'CyclonePreparedness' },
      { label: 'Fire Safety Equipment (Smoke Alarms)', value: 'FireSafetyEquipmentSmokeAlarms' },
      { label: 'Payment Information (MGA Ezipay)', value: 'PaymentInformationMGAEzipay' },
      {
        label: 'Payment Information (Hunter Premium Funding Previous Year/Rollover)',
        value: 'PaymentInformationHunterPremiumFundingPreviousYearRollOver',
      },
      {
        label: 'Payment Information (Hunter Premium Funding - New Monthly Contract)',
        value: 'PaymentInformationHunterPremiumFundingNewMonthlyContract',
      },
      { label: 'Policy Limits', value: 'PolicyLimits' },
      { label: 'Preventative Maintenance', value: 'PreventativeMaintenance' },
      { label: 'Excess Options', value: 'ExcessOptions' },
    ],
  },
  Householders: {
    recommendedCoversOptions: [
      { label: 'Contents Insurance', value: 'ContentsInsurance' },
      {
        label: 'Contents Insurance - Specified Contents and Portable Contents',
        value: 'ContentsSpecifiedPortable',
      },
      { label: 'Contents - Specified Contents, Special Contents and/or Valuables', value: 'ContentsSpecialValuables' },
      { label: 'Disaster Cover', value: 'DisasterCover' },
      { label: 'Home Business Extension', value: 'HomeBusinessExtension' },
    ],
    itemsToConsiderOptions: [
      { label: 'Building Sum Insured Calculator', value: 'BuildingSumInsuredCalculator' },
      { label: 'Circumstance Changes', value: 'CircumstanceChanges' },
      { label: 'Cyclone Preparedness', value: 'CyclonePreparedness' },
      { label: 'Fire Safety Equipment (Smoke Alarms)', value: 'FireSafetyEquipmentSmokeAlarms' },
      {
        label: 'Payment Information (MGA Ezipay)',
        value: 'PaymentInformationMGAEzipay',
      },
      {
        label: 'Payment Information (Hunter Premium Funding Previous Year/Rollover)',
        value: 'PaymentInformationHunterPremiumFundingPreviousYearRollOver',
      },
      {
        label: 'Payment Information (Hunter Premium Funding - New Monthly Contract)',
        value: 'PaymentInformationHunterPremiumFundingNewMonthlyContract',
      },
      { label: 'Policy Limits', value: 'PolicyLimits' },
      { label: 'Preventative Maintenance', value: 'PreventativeMaintenance' },
      { label: 'Excess Options', value: 'ExcessOptions' },
    ],
  },
};

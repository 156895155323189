/* eslint-disable arrow-body-style */
import React from 'react';
import { Content, Page, PageTitle } from 'pages/Report/components/Styled';

import { personalLinesCovers } from 'pages/Question/MasterRenewal/data';
import { CommercialLinesUninsuredRisks, CommercialLinesImportantItemsToConsider } from './CommercialLinesRisks';
import {
  MotorcycleUninsuredRisks,
  PleasureCraftUninsuredRisks,
  CaravanUninsuredRisks,
  MotorVehicleUninsuredRisks,
  LandlordUninsuredRisks,
  HouseholdersUninsuredRisks,
  MotorcycleImportantItemsToConsider,
  PleasureCraftImportantItemsToConsider,
  CaravanImportantItemsToConsider,
  MotorVehicleImportantItemsToConsider,
  LandlordImportantItemsToConsider,
  HouseholdersImportantItemsToConsider,
} from './PersonalLinesRisks';

export const MasterRenewalUninsuredRisks = ({ formName, reportState }) => {
  const getPersonalLinesUninsuredRisks = () =>
    Object.entries(reportState)
      .filter(([key]) => !!personalLinesCovers[key])
      .map(([key, value]) => ({ cover: key, value }));

  return (
    <>
      {(!!reportState?.recommendedCovers?.length || !!getPersonalLinesUninsuredRisks().length) && (
        <Page dynamic formName={formName}>
          <PageTitle>Recommendations</PageTitle>
          <Content>
            {(!!reportState?.recommendedCovers?.length || !!reportState?.businessPackRecommendedCovers?.length) && (
              <>
                <CommercialLinesUninsuredRisks
                  recommendedCovers={reportState?.recommendedCovers}
                  businessPackRecommendedCovers={reportState?.businessPackRecommendedCovers}
                />
              </>
            )}

            {!!getPersonalLinesUninsuredRisks().length && (
              <>
                {getPersonalLinesUninsuredRisks().map(
                  (risk) =>
                    !!risk.value.recommendedCovers?.length && (
                      <React.Fragment key={risk.cover}>
                        {risk.cover === 'Motorcycle' && (
                          <MotorcycleUninsuredRisks
                            recommendedCovers={risk.value.recommendedCovers}
                            cover={personalLinesCovers[risk.cover]}
                          />
                        )}
                        {risk.cover === 'PleasureCraft' && (
                          <PleasureCraftUninsuredRisks
                            recommendedCovers={risk.value.recommendedCovers}
                            cover={personalLinesCovers[risk.cover]}
                          />
                        )}
                        {risk.cover === 'Caravan' && (
                          <CaravanUninsuredRisks recommendedCovers={risk.value.recommendedCovers} cover={personalLinesCovers[risk.cover]} />
                        )}
                        {risk.cover === 'MotorVehicle' && (
                          <MotorVehicleUninsuredRisks
                            recommendedCovers={risk.value.recommendedCovers}
                            cover={personalLinesCovers[risk.cover]}
                          />
                        )}
                        {risk.cover === 'Landlords' && (
                          <LandlordUninsuredRisks
                            recommendedCovers={risk.value.recommendedCovers}
                            cover={personalLinesCovers[risk.cover]}
                          />
                        )}
                        {risk.cover === 'Householders' && (
                          <HouseholdersUninsuredRisks
                            recommendedCovers={risk.value.recommendedCovers}
                            cover={personalLinesCovers[risk.cover]}
                          />
                        )}
                      </React.Fragment>
                    ),
                )}
              </>
            )}
          </Content>
        </Page>
      )}

      <Page dynamic formName={formName}>
        <PageTitle>Important Items to Consider</PageTitle>
        <Content>
          {!!reportState?.importantItemsToConsider?.length && (
            <>
              <CommercialLinesImportantItemsToConsider importantItemsToConsider={reportState?.importantItemsToConsider} />
            </>
          )}

          {!!getPersonalLinesUninsuredRisks().length && (
            <>
              {getPersonalLinesUninsuredRisks().map(
                (risk) =>
                  !!risk.value.importantItemsToConsider?.length && (
                    <React.Fragment key={risk.cover}>
                      {risk.cover === 'Motorcycle' && (
                        <MotorcycleImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                      {risk.cover === 'PleasureCraft' && (
                        <PleasureCraftImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                      {risk.cover === 'Caravan' && (
                        <CaravanImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                      {risk.cover === 'MotorVehicle' && (
                        <MotorVehicleImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                      {risk.cover === 'Landlords' && (
                        <LandlordImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                      {risk.cover === 'Householders' && (
                        <HouseholdersImportantItemsToConsider
                          importantItemsToConsider={risk.value.importantItemsToConsider}
                          cover={personalLinesCovers[risk.cover]}
                        />
                      )}
                    </React.Fragment>
                  ),
              )}
            </>
          )}
        </Content>
      </Page>
    </>
  );
};

import React from 'react';
import { Button, Form } from 'antd';
import { ButtonContainer } from './Styled';

export const FormButtons = ({ nextText = 'NEXT', nextProps, prevText = 'PREVIOUS', prevProps, onPrev }) => (
  <Form.Item>
    <ButtonContainer size="large">
      {onPrev && (
        <Button size="large" type="primary" htmlType="button" onClick={onPrev} {...prevProps}>
          {prevText}
        </Button>
      )}
      <Button size="large" type="primary" htmlType="submit" {...nextProps}>
        {nextText}
      </Button>
    </ButtonContainer>
  </Form.Item>
);

/* eslint-disable no-use-before-define */
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Progress } from 'antd';
import { useReportState } from 'context';
import { Layout } from 'components';
import {
  MasterRenewalAlternateQuotation,
  MasterRenewalPremiums,
  Sign,
  MasterRenewalUninsuredRisks,
  MasterRenewalCovers,
} from 'pages/Question/components/Forms';
import { FormButtons, FormContainer, Notification, FormStatusContainer } from 'pages/Question/components/Styled';
import {
  options,
  businessPackCovers,
  renewalCovers,
  renewalCoversOrder,
  personalLinesCovers,
  commercialLinesCovers,
  personalLinesUninsuredRisksOptions,
  NoAlternativeQuotationSought,
} from './data';

export const MasterRenewal = () => {
  const history = useHistory();
  const location = useLocation();
  const isPersonalLineMasterForm = location.pathname.includes('personal-lines-master-renewal');
  const FORM_NAME = isPersonalLineMasterForm ? 'personalLinesMasterRenewal' : 'masterRenewal';
  const reportRoute = isPersonalLineMasterForm ? '/personal-lines-master-renewal-report' : '/master-renewal-report';

  const {
    reportState,
    formIndex,
    save,
    prevForm,
    nextForm,
    setFormIndex,
    questionSet,
    setQuestionSet,
    masterRenewalForms,
    setMasterRenewalForms,
  } = useReportState();
  const [formErrorMessage, setFormErrorMessage] = useState();

  const masterRenewalCovers = {
    name: 'masterRenewalCovers',
    component: (
      <MasterRenewalCovers
        covers={commercialLinesCovers}
        personalLinesCovers={personalLinesCovers}
        isPersonalLineMasterForm={isPersonalLineMasterForm}
      />
    ),
  };

  const masterRenewalUninsuredRisks = {
    name: 'masterRenewalRecommendations',
    component: <MasterRenewalUninsuredRisks options={options.uninsuredRisks} formName={FORM_NAME} />,
  };

  const masterRenewalSign = { name: 'masterRenewalSign', component: <Sign /> };

  useEffect(() => {
    if (questionSet !== FORM_NAME || !masterRenewalForms[formIndex]) {
      setQuestionSet(FORM_NAME);
      setFormIndex(0);
    }
  }, [formIndex, questionSet]);

  useEffect(
    () => () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    },
    [formIndex],
  );

  const currentForm = masterRenewalForms[formIndex];

  // initialize master form
  useEffect(() => {
    setMasterRenewalForms(masterRenewalForms[1] ? masterRenewalForms : [masterRenewalCovers, masterRenewalSign]);

    // build forms when the saved state contains selected covers
    buildMasterRenewalForms(
      reportState?.masterRenewal?.covers?.selectedCommercialLinesCovers,
      reportState?.masterRenewal?.covers?.selectedPersonalLineCovers,
    );
  }, []);

  const buildMasterRenewalForms = (selectedCovers = [], selectedPersonalLineCovers = []) => {
    const orderedCovers = [...selectedCovers, ...selectedPersonalLineCovers]
      .map((cover) => ({ cover, order: renewalCoversOrder[cover] }))
      .sort((coverA, coverB) => coverA.order - coverB.order)
      .map((cover) => cover.cover);

    const formsToIterate = orderedCovers.map((cover, index) => [
      {
        name: `${cover}Premiums`,
        component: <MasterRenewalPremiums options={options.premiums} cover={cover} showInsuredName={index === 0} />,
      },
      {
        name: `${cover}AlternateQuotation`,
        component: (
          <MasterRenewalAlternateQuotation
            options={options.alternateQuotation[cover]}
            cover={cover}
            personalLinesCovers={!!personalLinesCovers[cover]}
            commercialLinesCovers={!!commercialLinesCovers[cover]}
          />
        ),
      },
    ]);

    const questionForms =
      formsToIterate.length > 1
        ? [
            masterRenewalCovers,
            ...formsToIterate.flat(),
            {
              name: `masterRenewalPremiumsOverall`,
              component: (
                <MasterRenewalPremiums options={options.premiums} cover="SummaryOverall" showInsuredName={false} showOnlySummary />
              ),
            },
            masterRenewalUninsuredRisks,
            masterRenewalSign,
          ]
        : [masterRenewalCovers, ...formsToIterate.flat(), masterRenewalUninsuredRisks, masterRenewalSign];
    setMasterRenewalForms(questionForms);
  };

  const getMasterRenewalInitialValue = (recommended) => {
    let initialValue = {
      premiums: {},
      uninsuredRisks: {
        recommendedCovers: [recommended && recommended],
        importantItemsToConsider: options.uninsuredRisks.itemsToConsiderOptions.map((option) => option.value),
      },
    };

    // assign a placeholder for premium dynamic field (commercial lines)
    Object.keys(renewalCovers).forEach(
      // eslint-disable-next-line no-return-assign
      (cover) => {
        if (!personalLinesCovers[cover]) initialValue.premiums[cover] = { renewal: [{ insurer: '' }], lastYear: [{ insurer: '' }] };
      },
    );

    if (reportState?.masterRenewal) {
      initialValue = {
        ...reportState?.masterRenewal,
        premiums: { ...initialValue.premiums, ...reportState.masterRenewal?.premiums },
      };
    }

    return initialValue;
  };

  return (
    <Layout>
      <FormContainer>
        <Form.Provider
          onFormChange={(name, { changedFields, forms }) => {
            // reset custom checkbox input values when personal line alternate quotation 'No alternative quotations sought' is selected
            if (changedFields?.[0]?.value?.[0]?.value && changedFields[0].value[0].value === NoAlternativeQuotationSought) {
              if (changedFields[0]?.name[0] === 'alternateQuotation') {
                forms[name].setFieldsValue({
                  [changedFields?.[0]?.name[0]]: {
                    [changedFields?.[0]?.name[1]]: {
                      validation: {
                        premium: {
                          insurerName: undefined,
                          amount: undefined,
                        },
                      },
                    },
                  },
                });
              }
            }

            if (name === 'masterRenewalCovers' && changedFields[0]?.name) {
              // check if any cover has been selected
              if (
                !!forms[name].getFieldValue()?.covers?.selectedCommercialLinesCovers?.length ||
                !!forms[name].getFieldValue()?.covers?.selectedPersonalLineCovers?.length
              ) {
                setFormErrorMessage();
              } else {
                setFormErrorMessage('Please select 1 or more covers');
              }

              // build forms based on cover selectiion
              if (changedFields[0].name.includes('selectedCommercialLinesCovers')) {
                forms[name].setFieldsValue({
                  uninsuredRisks: {
                    recommendedCovers: changedFields[0].value,
                    importantItemsToConsider: options.uninsuredRisks.itemsToConsiderOptions.map((option) => option.value),
                  },
                });
                buildMasterRenewalForms(changedFields[0].value, forms[name].getFieldsValue().covers.selectedPersonalLineCovers);

                if (forms[name].getFieldValue()?.covers?.selectedCommercialLinesCovers.includes('BusinessPack')) {
                  forms[name].setFieldsValue({
                    uninsuredRisks: { businessPackRecommendedCovers: Object.keys(businessPackCovers).map((key) => key) },
                  });
                }
              }

              // handling personal lines covers selection
              // build forms, then update default selected uninsured risks
              if (changedFields[0].name.includes('selectedPersonalLineCovers')) {
                buildMasterRenewalForms(forms[name].getFieldsValue().covers.selectedCommercialLinesCovers, changedFields[0].value);
                const defaultCheckedUninsuredRisks = {};
                changedFields[0].value.forEach((cover) => {
                  defaultCheckedUninsuredRisks[cover] = {
                    recommendedCovers: personalLinesUninsuredRisksOptions[cover].recommendedCoversOptions.map((option) => option.value),
                    importantItemsToConsider: personalLinesUninsuredRisksOptions[cover].itemsToConsiderOptions.map(
                      (option) => option.value,
                    ),
                  };
                });
                forms[name].setFieldsValue({ uninsuredRisks: defaultCheckedUninsuredRisks });
              }
            }
          }}
          onFormFinish={(name, { values, forms }) => {
            forms[name].validateFields();
            const isCoverSelected =
              !!forms[name].getFieldValue()?.covers?.selectedCommercialLinesCovers?.length ||
              !!forms[name].getFieldValue()?.covers?.selectedPersonalLineCovers?.length;

            const stateToSave = {
              masterRenewal: {
                ...reportState?.masterRenewal,
                ...values,
                premiums: { ...reportState?.masterRenewal?.premiums, ...values?.premiums },
                alternateQuotation: { ...reportState?.masterRenewal?.alternateQuotation, ...values?.alternateQuotation },
                finished: name === masterRenewalForms[masterRenewalForms.length - 1].name,
              },
            };

            if (name === masterRenewalForms[masterRenewalForms.length - 1].name) {
              save(stateToSave);
              history.push(reportRoute);
            } else {
              save(stateToSave);

              // validation for master renewal cover selection form
              if (name === 'masterRenewalCovers') {
                if (isCoverSelected) {
                  setFormErrorMessage();
                  nextForm();
                } else {
                  setFormErrorMessage('Please select 1 or more covers');
                }
              } else {
                nextForm();
              }
            }
          }}
        >
          <Form
            name={currentForm?.name}
            colon={false}
            layout="vertical"
            requiredMark={false}
            initialValues={getMasterRenewalInitialValue()}
          >
            {formIndex > 0 && (
              <Notification>
                <FormStatusContainer>{`Form ${formIndex} / ${masterRenewalForms.length - 1}`}</FormStatusContainer>
                <Progress strokeLinecap="butt" percent={((formIndex * 100) / (masterRenewalForms.length - 1)).toFixed(0)} />
              </Notification>
            )}

            {formIndex > 0 && formIndex < masterRenewalForms.length - 1 && (
              <FormButtons
                nextText={formIndex === masterRenewalForms.length - 1 ? 'SUBMIT' : 'NEXT'}
                prevProps={{ ghost: true }}
                onPrev={formIndex === 0 ? undefined : prevForm}
              />
            )}

            {currentForm?.component}
            {formErrorMessage && <Notification>{formErrorMessage}</Notification>}
            <FormButtons
              nextText={formIndex === masterRenewalForms.length - 1 ? 'SUBMIT' : 'NEXT'}
              prevProps={{ ghost: true }}
              onPrev={formIndex === 0 ? undefined : prevForm}
            />
          </Form>
        </Form.Provider>
      </FormContainer>
    </Layout>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';
import { Card, Layout } from 'components';

const FormLinkButton = styled(Button)`
  width: 100%;
  margin: 10px auto;
`;

const FORMS = [
  { path: '/strata', name: 'Strata Renewal' },
  { path: '/master-renewal', name: 'Master Renewal' },
  { path: '/personal-lines-master-renewal', name: 'Personal Lines Master Renewal' },
];

export const FormSelection = () => (
  <Layout>
    <Card title="Form Selection">
      {FORMS.map(({ path, name }) => (
        <Link to={path} key={path}>
          <FormLinkButton size="large" type="primary" ghost>
            {name}
          </FormLinkButton>
        </Link>
      ))}
    </Card>
  </Layout>
);

import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { resetPassword } from 'auth';
import { Card, Layout } from 'components';

export const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await resetPassword(values.username);
      message.success(res);
    } catch (errMsg) {
      message.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Card title="Forget Password">
        <p>Please provide the user name of your account. We will send you an email that will allow you to reset your password.</p>
        <Form labelCol={{ span: 0 }} onFinish={handleSubmit}>
          <Form.Item name="username" label="Username" rules={[{ required: true, whitespace: true }]}>
            <Input size="large" placeholder="Username" spellCheck={false} />
          </Form.Item>
          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit" loading={loading}>
              {`${loading ? `Sending` : `Send`} verification email`}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
};

import React, { useRef } from 'react';
import { Form, Input, Space, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Currency, CurrencyInput, Percentage } from 'components';
import { Variation, PremiumTotalContainer, StyledFormItem } from 'pages/Question/components/Styled';
import { unionBy } from 'lodash';
import { CustomCheckbox } from 'pages/Question/components/CustomCheckBox';

export const PremiumDynamicFields = ({ premiums, formName, premiumTotal }) => {
  const inputRef = useRef();

  const setFocus = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.List name={formName || ['premiums', 'renewal']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Space key={field.key} align="baseline">
              <Form.Item
                {...field}
                name={[field.name, 'insurer']}
                fieldKey={[field.fieldKey, 'insurer']}
                rules={[{ required: true, message: 'Please add insurer', whitespace: true }]}
              >
                <Input size="large" placeholder="Insurer" ref={inputRef} />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'premium']}
                fieldKey={[field.fieldKey, 'premium']}
                rules={[
                  { required: true, message: 'Please add premium' },
                  () => ({
                    validator(_, amount) {
                      if (amount === undefined || amount === null || amount > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Must be greater than 0'));
                    },
                  }),
                ]}
              >
                <CurrencyInput size="large" placeholder="Renewal premium" />
              </Form.Item>
              <Percentage value={premiums?.[index]?.premium} total={premiumTotal} />
              {field.key !== 0 && (
                <MinusCircleOutlined
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              )}
            </Space>
          ))}
          {!Number.isNaN(premiumTotal) && (
            <PremiumTotalContainer>
              <strong>Renewal Premium Total</strong>
              <Variation>
                <strong>
                  <Currency value={premiumTotal} />
                </strong>
              </Variation>
            </PremiumTotalContainer>
          )}
          <Form.Item>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                add();
                setFocus();
              }}
              block
            >
              <PlusOutlined /> Add more
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const AlternationQuoteDynamicFields = ({ formName, inputType = 'altQuote' }) => {
  const inputRef = useRef();

  const setFocus = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.List name={formName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Space key={field.key} align="baseline" style={{ width: '100%' }}>
              <Form.Item
                {...field}
                name={[field.name, 'insurer']}
                fieldKey={[field.fieldKey, 'insurer']}
                rules={[{ required: true, message: 'Please add insurer', whitespace: true }]}
              >
                <Input size="large" placeholder="Insurer" ref={inputRef} />
              </Form.Item>
              {inputType === 'altQuote' && (
                <Form.Item
                  {...field}
                  name={[field.name, 'premium']}
                  fieldKey={[field.fieldKey, 'premium']}
                  rules={[
                    { required: true, message: 'Please add premium' },
                    () => ({
                      validator(_, amount) {
                        if (amount === undefined || amount === null || amount > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Must be greater than 0'));
                      },
                    }),
                  ]}
                >
                  <CurrencyInput size="large" placeholder="Renewal premium" />
                </Form.Item>
              )}

              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                add();
                setFocus();
              }}
              block
            >
              <PlusOutlined /> Add more
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const OtherPremiumDynamicFields = ({ premiumTotal, formName, premiums }) => {
  const inputRef = useRef();

  const setFocus = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.List name={formName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Space key={field.key} align="baseline">
              <Form.Item
                {...field}
                name={[field.name, 'insurer']}
                fieldKey={[field.fieldKey, 'insurer']}
                rules={[{ required: true, message: 'Please add insurer', whitespace: true }]}
              >
                <Input size="large" placeholder="Insurer" ref={inputRef} />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'premium']}
                fieldKey={[field.fieldKey, 'premium']}
                rules={[
                  { required: true, message: 'Please add premium' },
                  () => ({
                    validator(_, amount) {
                      if (amount === undefined || amount === null || amount > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Must be greater than 0'));
                    },
                  }),
                ]}
              >
                <CurrencyInput size="large" placeholder="Renewal premium" />
              </Form.Item>
              <Percentage value={premiums?.[index]?.premium} total={premiumTotal} />
              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                add();
                setFocus();
              }}
              block
            >
              <PlusOutlined /> Add more
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const ReasonDynamicFields = ({ getFieldValue, setFieldsValue, formName }) => {
  const currentValue = getFieldValue(formName);
  const inputRef = useRef();

  const handleChange = (changedValue) => {
    setFieldsValue(
      changedValue.checked
        ? unionBy([changedValue], currentValue, 'value')
        : currentValue?.filter((item) => item?.value !== changedValue?.value),
    );
  };

  const setFocus = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.List name={formName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Space key={field.key} align="baseline" style={{ width: '100%' }}>
              <StyledFormItem
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                rules={[
                  {
                    required: true,
                    transform: (value) => {
                      if (!value.checked) {
                        return 'not required';
                      }
                      return value.reason;
                    },
                    message: 'Please add description',
                    whitespace: true,
                  },
                ]}
              >
                <CustomCheckbox
                  value={currentValue?.[field.fieldKey]?.otherReason}
                  onChange={handleChange}
                  inputRef={inputRef}
                  type="formList"
                />
              </StyledFormItem>
              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                add();
                setFocus();
              }}
              block
            >
              <PlusOutlined /> Add
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

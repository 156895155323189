import React from 'react';
import { Button, Form, Space } from 'antd';
import styled from 'styles';

const ButtonContainer = styled(Space)`
  margin-top: ${({ theme }) => theme.size.spacing.xxl}px;
  display: flex;
  justify-content: center;
`;

export const FormButtons = ({ nextText = 'NEXT', nextProps, prevText = 'PREVIOUS', prevProps, onPrev }) => (
  <Form.Item>
    <ButtonContainer size="large">
      {onPrev && (
        <Button size="large" type="primary" htmlType="button" onClick={onPrev} {...prevProps}>
          {prevText}
        </Button>
      )}
      <Button size="large" type="primary" htmlType="submit" {...nextProps}>
        {nextText}
      </Button>
    </ButtonContainer>
  </Form.Item>
);

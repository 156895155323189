import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import axios from 'axios';
import csso from 'csso';
import { ThemeProvider } from 'styles';
import { AuthProvider } from 'auth';
import { ReportStateContext, UserProfileContext } from 'context';
import { PageFooterTemplate, PageHeaderTemplate } from 'pages/Report/components/Styled';

const HOSTNAME = process.env.REACT_APP_HOSTNAME;

const renderWithWrapper = (component, state) => (
  <ThemeProvider>
    <AuthProvider>
      <UserProfileContext.Provider value={{ userProfile: state?.userProfile }}>
        <ReportStateContext.Provider value={{ reportState: state?.reportState }}>{component}</ReportStateContext.Provider>
      </UserProfileContext.Provider>
    </AuthProvider>
  </ThemeProvider>
);

const minify = (styleStr) => `<style>${csso.minify(styleStr).css}</style>`;

const getTemplate = (component, state) => {
  const html = renderToStaticMarkup(renderWithWrapper(component, state)).replace(/\/static\/media/g, `${HOSTNAME}/static/media`);
  const styles =
    process.env.NODE_ENV === 'development'
      ? [...document.head.querySelectorAll('style')].map((item) => item.outerHTML).join('')
      : [...document.head.querySelectorAll('link[rel="stylesheet"]')]
          .map((item) => `<link href="${item.href}" rel="stylesheet" />`)
          .join('');
  const styledComponentsStyles = minify(
    [...document.querySelector('style[data-styled]').sheet.cssRules].map((item) => item.cssText).join(''),
  );
  const injectedStyles =
    navigator.userAgent.includes('Firefox/') || navigator.userAgent.includes('Chrome/') || navigator.userAgent.includes('Trident/')
      ? `${styles}${styledComponentsStyles}`.replace(/\/static\/media/g, `${HOSTNAME}/static/media`)
      : `${styles}${styledComponentsStyles}`;
  return `<html><head><meta charset="utf-8">${injectedStyles}</head><body>${html}</body></html>`;
};

const getHeaderFooterTemplate = (component, state) => {
  const html = renderToStaticMarkup(renderWithWrapper(component, state));
  const styles = minify([...document.querySelector('style[data-styled]').sheet.cssRules].map((item) => item.cssText).join(''));
  return `<html><head><meta charset="utf-8">${styles}</head><body>${html}</body></html>`;
};

export const downloadReport = (content, cover, state, formName) =>
  axios.post(
    process.env.REACT_APP_API_EXPORT,
    {
      format: 'A4',
      displayHeaderFooter: true,
      margin: { top: '100px', bottom: '80px' },
      content: getTemplate(content, state),
      frontMatter: getTemplate(cover, state),
      headerTemplate: getHeaderFooterTemplate(<PageHeaderTemplate formName={formName} />, state),
      footerTemplate: getHeaderFooterTemplate(<PageFooterTemplate />),
    },
    { responseType: 'arraybuffer' },
  );

import React from 'react';
import styled from 'styles';
import { Banner, Page, Paragraph } from 'pages/Report/components/Styled';
import aboutBg from 'assets/about.jpg';

const Title = styled.h1`
  margin-top: 64px;
  margin-bottom: ${({ theme }) => theme.size.spacing.xl}px;
  color: ${({ theme }) => theme.palette.paper[0]};
  font-weight: bold;
`;

const Content = styled.div`
  position: absolute;
  bottom: 85px;
  padding-left: 48px;
  width: 100%;
  color: ${({ theme }) => theme.palette.paper[0]};
`;

const Padding = styled.div`
  float: right;
  height: 503px;
  width: 416px;
  shape-outside: polygon(30.7% 0%, 100% 0%, 100% 100%, 0% 100%);
`;

export const About = () => (
  <Page cover>
    <Banner bg={aboutBg}>
      <Content>
        <Padding />
        <Title>ABOUT MGA</Title>
        <Paragraph inverted>
          MGA Insurance Brokers have been specialists in general insurance for over 40 years and can provide advice across a broad spectrum
          of industries. MGA commenced operations in 1975 and is one of the top 10 insurance brokerages in Australia with offices throughout
          mainland Australia and one office in Asia.
        </Paragraph>
        <Paragraph inverted>
          With over 65,000 clients, MGA&apos;s philosophy has been to maintain steady growth, with a branch network through Australia. Our
          reputation and strength in regional areas is complimented with our presence in most capital cities.
        </Paragraph>
      </Content>
    </Banner>
  </Page>
);

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { useReportState } from 'context';
import { Layout } from 'components';
import { AlternateQuotation, Premiums, Sign, UninsuredRisks } from 'pages/Question/components/Forms';
import { FormButtons, FormContainer } from 'pages/Question/components/Styled';
import { options } from 'pages/Question/WorkerCompensation/data';

const questionForms = [
  { name: 'workerCompensationPremiums', component: <Premiums options={options.premiums} /> },
  { name: 'workerCompensationAlternateQuotation', component: <AlternateQuotation options={options.alternateQuotation} /> },
  {
    name: 'workerCompensationRecommendations',
    component: <UninsuredRisks options={options.unisuredRisks} formName="workerCompensation" />,
  },
  { name: 'workerCompensationSign', component: <Sign /> },
];

const DEFAULT_VALUES = {
  premiums: { renewal: [{ insurer: '' }], lastYear: [{ insurer: '' }] },
  uninsuredRisks: {
    importantItemsToConsider: ['ClaimsTimeline', 'DeclaringWages', 'Contractors', 'QuarterlyPayments'],
    recommendedCovers: ['PreemploymentCheck', 'WHSIncidentReporting', 'ReturnToWorkPlan', 'CommonDueDating'],
  },
};

export const WorkerCompensation = () => {
  const history = useHistory();
  const { reportState, formIndex, save, prevForm, nextForm, setFormIndex, questionSet, setQuestionSet } = useReportState();

  useEffect(() => {
    if (questionSet !== 'workerCompensation' || !questionForms[formIndex]) {
      setQuestionSet('workerCompensation');
      setFormIndex(0);
    }
  }, [questionSet, setQuestionSet, setFormIndex, formIndex]);

  useEffect(
    () => () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    },
    [formIndex],
  );

  const currentForm = questionForms[formIndex];

  let initialValues;
  if (reportState?.workerCompensation && reportState?.workerCompensation?.premiums?.renewal?.length > 0) {
    initialValues = reportState.workerCompensation;
  } else {
    initialValues = DEFAULT_VALUES;
  }

  return (
    <Layout>
      <FormContainer>
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            forms[name].validateFields();
            if (name === questionForms[questionForms.length - 1].name) {
              save({ workerCompensation: { ...reportState.workerCompensation, ...values, finished: true } });
              history.push('/worker-compensation-report');
            } else {
              save({ workerCompensation: { ...reportState?.workerCompensation, ...values } });
              nextForm();
            }
          }}
        >
          <Form
            name={currentForm?.name && currentForm.name}
            colon={false}
            layout="vertical"
            requiredMark={false}
            initialValues={initialValues}
          >
            {currentForm?.component && currentForm.component}
            <FormButtons
              nextText={formIndex === questionForms.length - 1 ? 'SUBMIT' : 'NEXT'}
              prevProps={{ ghost: true }}
              onPrev={formIndex === 0 ? undefined : prevForm}
            />
          </Form>
        </Form.Provider>
      </FormContainer>
    </Layout>
  );
};

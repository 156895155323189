import React from 'react';
import styled from 'styles';
import { FormatDate } from 'components';
import { Banner, Heading, Page } from 'pages/Report/components/Styled';
import coverBg from 'assets/cover.jpg';

const InsurerName = styled(Heading)`
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 0;
`;

const ReportTitle = styled(Heading).attrs({ level: 2 })`
  font-family: var(--text-font);
  margin: 0;
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 240px;
  padding-left: 48px;
  width: 100%;
  color: ${({ theme }) => theme.palette.paper[0]};

  ${InsurerName} {
    color: inherit;
    font-size: 40px;
  }
`;

const Padding = styled.div`
  float: right;
  height: 200px;
  width: 400px;
  shape-outside: polygon(12.2% 0%, 100% 0%, 100% 100%, 0% 100%);
`;

const Footer = styled.footer`
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 48px 48px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${ReportTitle} {
    text-transform: none;
  }
`;

export const Cover = ({ insuredName, title }) => (
  <Page cover>
    <Banner bg={coverBg}>
      <Wrapper>
        <Padding />
        <InsurerName>{insuredName}</InsurerName>
      </Wrapper>
    </Banner>
    <Footer>
      <ReportTitle>{title}</ReportTitle>
      <span>
        DATE ISSUED <FormatDate />
      </span>
    </Footer>
  </Page>
);

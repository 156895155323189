/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const DATA_KEY = 'report_data';

export const ReportStateContext = createContext();

export const ReportStateProvider = ({ children }) => {
  const [reportState, setReportData] = useState();
  const [formIndex, setFormIndex] = useState(0);
  const [questionSet, setQuestionSet] = useState();
  const [masterRenewalForms, setMasterRenewalForms] = useState([]);

  const save = useCallback(
    (data) => {
      const newState = { ...reportState, ...data };
      setReportData(newState);
      localStorage.setItem(DATA_KEY, JSON.stringify(newState));
    },
    [reportState],
  );

  const reset = () => {
    setReportData();
    localStorage.removeItem(DATA_KEY);
  };

  const resetMasterForm = () => {
    const newState = { ...reportState, masterRenewal: {} };
    setReportData(newState);
    localStorage.setItem(DATA_KEY, JSON.stringify(newState));
  };

  const prevForm = useCallback(() => {
    if (formIndex > 0) {
      setFormIndex(formIndex - 1);
    }
  }, [formIndex]);

  const nextForm = useCallback(() => {
    setFormIndex(formIndex + 1);
  }, [formIndex]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(DATA_KEY));
    setReportData(data);
  }, []);

  const value = useMemo(
    () => ({
      reportState,
      formIndex,
      save,
      reset,
      resetMasterForm,
      prevForm,
      nextForm,
      setFormIndex,
      questionSet,
      setQuestionSet,
      masterRenewalForms,
      setMasterRenewalForms,
    }),
    [
      reportState,
      formIndex,
      save,
      nextForm,
      prevForm,
      reset,
      resetMasterForm,
      setFormIndex,
      questionSet,
      setQuestionSet,
      masterRenewalForms,
      setMasterRenewalForms,
    ],
  );

  return <ReportStateContext.Provider value={value}>{children}</ReportStateContext.Provider>;
};

export const useReportState = () => useContext(ReportStateContext);

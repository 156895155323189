import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';
import { FormTitle, CheckboxGroup } from 'pages/Question/components/Styled';
import { renewalCovers, personalLinesCovers, commercialLinesCovers } from 'pages/Question/MasterRenewal/data';
import { CustomCheckboxGroup } from 'pages/Question/components/CustomCheckBox';
import { AlternationQuoteDynamicFields, ReasonDynamicFields } from 'pages/Question/components/DynamicFields';

export const MasterRenewalAlternateQuotation = ({ cover, options }) => (
  <>
    <FormTitle>{`${renewalCovers[cover]} - Alternate Quotation/s`}</FormTitle>
    {personalLinesCovers[cover] && (
      <Form.Item name={['alternateQuotation', cover, 'premium']} label="Alternate Quotation Premium">
        <CustomCheckboxGroup
          options={options.alternateQuotationPremiumOptions}
          type="personalLine"
          formName={['alternateQuotation', cover, 'validation', 'premium']}
        />
      </Form.Item>
    )}

    {commercialLinesCovers[cover] && (
      <Form.Item label="Alternate Quotation Premium">
        <AlternationQuoteDynamicFields formName={['alternateQuotation', cover, 'premium']} />
      </Form.Item>
    )}

    <Form.Item name={['alternateQuotation', cover, 'reason']} label="Reason">
      <CheckboxGroup>
        <Row gutter={[10, 20]}>
          {options.reasonOptions.map((item) => (
            <Col key={item.value} xs={24} sm={12} md={8}>
              <Checkbox value={item.value}>{item.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
    </Form.Item>

    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.otherImposedExcess !== curValues.otherImposedExcess}>
      {({ getFieldValue, setFieldsValue }) => (
        <ReasonDynamicFields
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          formName={['alternateQuotation', cover, 'otherReason']}
        />
      )}
    </Form.Item>

    <Form.Item label="Did any insurer decline to offer quotation?">
      <AlternationQuoteDynamicFields formName={['alternateQuotation', cover, 'decline']} inputType="decline" />
    </Form.Item>

    {options.declineOptions && options.declineOptions.length > 0 && (
      <Form.Item name={['alternateQuotation', cover, 'declineReason']} label="Decline Reason">
        <CustomCheckboxGroup options={options.declineOptions} type="decline" layout="horizontal" />
      </Form.Item>
    )}

    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.otherImposedExcess !== curValues.otherImposedExcess}>
      {({ getFieldValue, setFieldsValue }) => (
        <ReasonDynamicFields
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
          formName={['alternateQuotation', cover, 'declineOtherReason']}
        />
      )}
    </Form.Item>
  </>
);

import React from 'react';
import { Checkbox, Row, Form } from 'antd';
import { CenteredCol, CheckboxGroup } from 'pages/Question/components/Styled';
import { Card } from 'components';
import { useReportState } from 'context';

export const MasterRenewalCovers = ({ covers, personalLinesCovers, isPersonalLineMasterForm }) => {
  const commercialLinesOptions = covers ? Object.entries(covers).map(([key, value]) => ({ label: value, value: key })) : [];
  const personalLinesOptions = personalLinesCovers
    ? Object.entries(personalLinesCovers).map(([key, value]) => ({ label: value, value: key }))
    : [];
  // eslint-disable-next-line no-unused-vars
  const { resetMasterForm } = useReportState();

  return (
    <Card title="Select covers to include">
      {!isPersonalLineMasterForm && (
        <Form.Item label="Commercial Lines" name={['covers', 'selectedCommercialLinesCovers']}>
          <CheckboxGroup>
            <Row gutter={[10, 20]} align="top">
              {commercialLinesOptions.map((item) => (
                <CenteredCol key={item.value} xs={24} sm={12}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </CenteredCol>
              ))}
            </Row>
          </CheckboxGroup>
        </Form.Item>
      )}

      <Form.Item label="Personal Lines" name={['covers', 'selectedPersonalLineCovers']}>
        <CheckboxGroup>
          <Row gutter={[10, 20]} align="top">
            {personalLinesOptions.map((item) => (
              <CenteredCol key={item.value} xs={24} sm={12}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </CenteredCol>
            ))}
          </Row>
        </CheckboxGroup>
      </Form.Item>
    </Card>
  );
};

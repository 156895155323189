export const options = {
  premiums: {
    increaseReasonOptions: [
      {
        label: 'strata rate increases in line with current market conditions',
        value: 'strata rate increases in line with current market conditions',
      },
      { label: 'previous claims made on your policy', value: 'previous claims made on your policy' },
      { label: 'ongoing claims made on your policy', value: 'ongoing claims made on your policy' },
    ],
    decreaseReasonOptions: [
      {
        label: 'reduction in coverage',
        value: 'reduction in coverage',
      },
      { label: 'increase in excess', value: 'increase in excess' },
      { label: 'historic claims removed from the rating structure', value: 'historic claims removed from the rating structure' },
      {
        label: 'we have successfully negotiated a reduction in premiums',
        value: 'we have successfully negotiated a reduction in premiums',
      },
    ],
  },
  currentExcesses: {
    imposedExcessOption: [{ label: 'Water Damage', value: 'Water Damage' }],
  },
  alternateQuotation: {
    alternateQuotationPremiumOptions: [
      { label: 'TIO', value: 'TIO' },
      { label: 'CHU', value: 'CHU' },
      // { label: 'Other', value: 'Other' },
      { label: 'No alternative quotations sought', value: 'No alternative quotations sought' },
    ],
    reasonOptions: [
      { label: 'limited cover', value: 'limited cover' },
      { label: 'premium not being competitive', value: 'premium not being competitive' },
      { label: 'imposed excess', value: 'imposed excess' },
    ],
    alternateQuotationDeclineOptions: [
      { label: 'TIO', value: 'TIO' },
      { label: 'CHU', value: 'CHU' },
      { label: 'Other', value: 'DeclineOther' },
    ],
    declineOptions: [
      { label: 'previous claims history', value: 'previous claims history' },
      { label: 'ongoing/active claims', value: 'ongoing/active claims' },
      { label: 'nature of tenancies in situ', value: 'nature of tenancies in situ' },
      { label: 'location', value: 'location' },
      { label: 'sums insured', value: 'sums insured' },
      { label: 'known defects', value: 'known defects' },
      { label: 'construction', value: 'construction' },
      { label: 'other decline reason', value: 'other decline reason' },
    ],
  },
  uninsuredRisks: {
    recommendedCoversOptions: [
      { label: 'Office Bearers Liability', value: 'OfficeBearersLiability' },
      { label: 'Catastrophe Cover', value: 'CatastropheCover' },
      { label: 'Flood Cover', value: 'FloodCover' },
      { label: 'Machinery Breakdown', value: 'MachineryBreakdown' },
      { label: 'Voluntary Workers', value: 'VoluntaryWorkers' },
      { label: 'Storm Surge', value: 'StormSurge' },
      { label: 'Loss of Rent/Temporary Accommodation', value: 'LossOfRentTemporaryAccommodation' },
      { label: 'Fidelity Guarantee', value: 'FidelityGuarantee' },
    ],
    itemsToConsiderOptions: [
      { label: 'Building Valuations', value: 'BuildingValuations' },
      { label: 'Roof Inspections', value: 'RoofInspections' },
      { label: 'Fire Safety Equipment', value: 'FireSafetyEquipment' },
      { label: 'Leak Detection', value: 'LeakDetection' },
      { label: 'Cyclone Preparedness', value: 'CyclonePreparedness' },
      { label: 'Preventative Maintenance', value: 'PreventativeMaintenance' },
      { label: 'Office Bearer’s Liability', value: 'OfficeBearersLiability' },
    ],
  },
};

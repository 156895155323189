/* eslint-disable no-shadow */
import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { CheckboxGroup, FormTitle } from 'pages/Question/components/Styled';

export const UninsuredRisks = ({ options, formName }) => {
  const { recommendedCoversOptions = [], itemsToConsiderOptions = [] } = options;

  return (
    <>
      <FormTitle>{formName === 'strata' ? 'Uninsured Risks' : 'Recommendation'}</FormTitle>
      <Form.Item name={['uninsuredRisks', 'recommendedCovers']} label="Select recommended covers">
        <CheckboxGroup>
          <Row gutter={[10, 20]}>
            {recommendedCoversOptions.map((item) => (
              <Col key={item.value} xs={24} sm={12} md={8}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </Form.Item>

      <FormTitle>Important Items to Consider</FormTitle>

      <Form.Item name={['uninsuredRisks', 'importantItemsToConsider']} label="Select items to consider/remember">
        <CheckboxGroup>
          <Row gutter={[10, 20]}>
            {itemsToConsiderOptions.map((item) => (
              <Col key={item.value} xs={24} sm={12} md={8}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </Form.Item>
    </>
  );
};

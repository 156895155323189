import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import FileSaver from 'file-saver';
import { useAuth } from 'auth';

export const Download = ({ children, onDownload, fileName }) => {
  const [downloading, setDownloading] = useState(false);
  const { pdfDownload } = useAuth();

  const download = async () => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    notification.open({
      key: 'downloading',
      message: 'Downloading',
      duration: 0,
      icon: <Spin />,
    });
    try {
      const res = await onDownload();
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      FileSaver.saveAs(blob, fileName);
      notification.close('downloading');
      notification.success({ key: 'download_success', message: 'Download succeeded' });
      pdfDownload();
    } catch (err) {
      notification.close('downloading');
      notification.error({ key: 'download_fail', message: 'Download failed' });
    }
    setDownloading(false);
  };

  return children(download, downloading);
};

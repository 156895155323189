import React from 'react';
import { Heading, Indent, Paragraph, TitledParagraph } from 'pages/Report/components/Styled';

export const CaravanUninsuredRisks = ({ recommendedCovers = [], cover }) => {
  const UninsuredRisks = () => (
    <>
      {!!recommendedCovers?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {recommendedCovers?.includes('AccessoriesModificationsCaravan') && (
              <TitledParagraph>
                <Heading level={3}>Accessories/Modifications to the Caravan</Heading>
                <Paragraph>
                  Any non-standard accessories and/or modifications need to be specified on your policy. Please provide our office with the
                  items to be listed and their individual values.
                </Paragraph>
                <Paragraph>
                  Please note that when you make a claim for loss or damages, you will be required to provide proof of ownership and of its
                  value/s or your claim may not be paid.
                </Paragraph>
                <Paragraph>
                  Please note some insurers include an accessory sub-limit in their cover. Please refer to the Product Disclosure Statement
                  (PDS) for further accessory and limit information.
                </Paragraph>
                <Paragraph>
                  Please note if you wish for an Annexe to be covered it is required to be specified on your policy. Please refer to your
                  policy schedule for Annexe information, if this needs to be updated or Annexe cover is required please contact our office.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ContentsInsurance') && (
              <TitledParagraph>
                <Heading level={3}>Contents Insurance</Heading>
                <Paragraph>
                  Some insurers may include a minimum contents sum insured amount. Please refer to your policy schedule for the contents sum
                  insured information
                </Paragraph>
                <Paragraph>
                  If you wish to add or increase your contents coverage, please contact our office if you wish to include contents coverage
                  in your policy.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('StorageOfCaravan') && (
              <TitledParagraph>
                <Heading level={3}>Storage of Caravan</Heading>
                <Paragraph>
                  You must advise our office of the normal storage location of your Caravan and if there is a material change in the use of
                  your Caravan.
                </Paragraph>
                <Paragraph>The insurer may include terms and conditions in the policy depending on how the Caravan is stored.</Paragraph>
                <Paragraph>
                  If the storage information or use of the Caravan is incorrect on your policy schedule you may not be covered in the event
                  of a claim.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <UninsuredRisks />;
};

export const CaravanImportantItemsToConsider = ({ importantItemsToConsider = [], cover }) => {
  const ImportantItemsToConsider = () => (
    <>
      {!!importantItemsToConsider?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {importantItemsToConsider?.includes('Maintenance') && (
              <TitledParagraph>
                <Heading level={3}>Maintenance</Heading>
                <Paragraph>
                  You must ensure that your Caravan and its accessories are maintained and are in good state of repairs and condition. Any
                  loss or damage caused by poor maintenance will not be covered under the policy.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('DutyOfDisclosure') && (
              <TitledParagraph>
                <Heading level={3}>Duty of Disclosure</Heading>
                <Paragraph>
                  We refer you to &apos;Your Duty of Disclosure&apos; located on Page 2 of your policy schedule and which is also contained
                  in your PDS. Should you need to declare anything to the insurer or have any other queries please contact us on{' '}
                  <strong>(08) 8943 1200</strong>.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <ImportantItemsToConsider />;
};

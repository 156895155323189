import React from 'react';
import styled, { css } from 'styles';

export const Article = styled.article`
  width: fit-content;
  min-width: 100%;
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.palette.paper[100]};
  counter-reset: page;
  line-height: 2.4;

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: ${({ theme }) => theme.size.spacing.lg}px;
    line-height: 3;
  }

  h3 {
    font-size: 18px;
    margin-bottom: ${({ theme }) => theme.size.spacing.lg}px;
  }

  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }

  @media screen and (max-width: 480px) {
    transform: scale(0.45);
    transform-origin: top left;
  }

  @media print {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
`;

const PageHeaderWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

const PageHeaderContainer = styled.div`
  width: 580px;
  padding-right: 80px;
  text-align: right;
  line-height: 2;
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  border-bottom: 2px solid ${({ theme }) => theme.palette.branding.primary.normal};

  @media print {
    width: 330px;
    margin-left: auto;
    padding: 5px 60px;
    font-size: 10px;
    line-height: 1.5;
  }
`;

const PageHeaderTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.size.spacing.md}px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const PageHeaderTemplate = ({ formName }) => (
  <PageHeaderContainer>
    {formName === 'workerCompensation' && <PageHeaderTitle>MGA Darwin Worker&apos;s Compensation Presentation</PageHeaderTitle>}
    {formName === 'strata' && <PageHeaderTitle>MGA Darwin Strata Presentation</PageHeaderTitle>}
    {formName === 'commercialMotor' && <PageHeaderTitle>MGA Darwin Commercial Motor Presentation</PageHeaderTitle>}
    {(formName === 'masterRenewal' || formName === 'personalLinesMasterRenewal') && (
      <PageHeaderTitle>MGA Darwin Renewal Presentation</PageHeaderTitle>
    )}
  </PageHeaderContainer>
);

const PageHeader = ({ formName }) => (
  <PageHeaderWrapper>
    <PageHeaderTemplate formName={formName} />
  </PageHeaderWrapper>
);

const StyledPageFooter = styled.div`
  width: 54px;
  height: 16px;
  margin-left: auto;
  padding-top: 2px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  color: ${({ theme }) => theme.palette.paper[0]};
  background-color: ${({ theme }) => theme.palette.branding.primary.normal};
  -webkit-print-color-adjust: exact;
`;

export const PageFooterTemplate = () => <StyledPageFooter className="pageNumber" />;

const StyledPage = styled.section`
  position: relative;
  width: 840px;
  height: ${({ dynamic }) => (dynamic ? 'auto' : '1188px')};
  margin: 0 auto ${({ theme }) => theme.size.spacing.xl}px;
  background-color: ${({ theme }) => theme.palette.paper[0]};
  box-shadow: 2px 4px 5px 0 ${({ theme }) => theme.palette.greys[50]};
  ${({ dynamic }) =>
    dynamic &&
    css`
      min-height: 1188px;
    `}

  @media print {
    height: auto;
    min-height: auto;
    margin: 0;
    padding: 0;
    box-shadow: none;
    page-break-after: always;
  }
`;

export const Page = ({ children, cover, dynamic, formName }) => (
  <StyledPage dynamic={dynamic}>
    {!cover && <PageHeader formName={formName} />}
    {children}
  </StyledPage>
);

export const Paragraph = styled.p`
  color: ${({ inverted, theme }) => (inverted ? theme.palette.paper[0] : theme.palette.branding.primary.normal)};
  text-align: ${({ align = 'justify' }) => align};
  line-height: 2;
`;

export const TitledParagraph = styled.div`
  @media print {
    margin-bottom: ${({ theme }) => theme.size.spacing.xl}px;
    page-break-inside: avoid;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  margin: 0 80px;
  padding-bottom: 80px;
  word-wrap: break-word;

  @media print {
    padding-bottom: 0;
  }
`;

export const Figure = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size.spacing.xxl}px;
`;

export const List = styled.ul`
  color: ${({ theme }) => theme.palette.branding.primary.normal};
`;

export const Indent = styled.div`
  padding-left: 25px;
`;

export const Star = styled.sup`
  top: 0;
  font-size: 14px;
  ::after {
    content: '*';
  }
`;

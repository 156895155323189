import React from 'react';
import { Content, List, Page, PageTitle, Paragraph } from 'pages/Report/components/Styled';

export const ValueProposition = ({ formName }) => (
  <Page formName={formName}>
    <PageTitle>Value Proposition</PageTitle>
    <Content>
      {formName === 'strata' ? (
        <>
          <Paragraph>
            MGA Insurance Group Darwin is a locally owned and operated general insurance brokerage specialising in Residential and
            Commercial Strata insurance. We are a team of Territorians with significant experience across all facets of the insurance
            industry. We are part of MGA Insurance Group which is one of the top 10 insurance brokerages in Australia with 38 offices
            throughout the country.
          </Paragraph>
          <Paragraph>
            At MGA Darwin we have an in-depth knowledge of the unique insurance needs of the Top End and understand that claim outcomes are
            invariably tied to the quality of the insurance product. We pride ourselves on the long-term relationships we build with our
            clients, insurers and loss adjusters which enables us to provide peace of mind and protection at the best value.
          </Paragraph>
          <Paragraph>
            As specialist Residential and Commercial Strata insurance brokers we provide a number of services to not only identify your
            insurance risks, but to also mitigate and manage them, and if the worst happens - to manage your claim to the highest service
            level. These include:
          </Paragraph>
          <List>
            <li>New business quoting and advice</li>
            <li>Renewal negotiations</li>
            <li>Policy comparisons</li>
            <li>Excess negotiations</li>
            <li>Claims management</li>
            <li>Risk recommendations</li>
          </List>
        </>
      ) : (
        <>
          <Paragraph>
            MGA Insurance Group Darwin is a locally owned and operated general insurance brokerage. We are a team of Territorians with
            significant experience across all facets of the insurance industry. We are part of MGA Insurance Group which is one of the top
            10 insurance brokerages in Australia with 38 offices throughout the country.
          </Paragraph>
          <Paragraph>
            At MGA Darwin we have an in-depth knowledge of the unique insurance needs of the Top End and understand that claims outcomes are
            invariably tied to the quality of the insurance product. We pride ourselves on the long- term relationships we build with our
            clients, insurers and loss adjusters which enables us to provide peace of mind and protection at the best value.
          </Paragraph>
          <Paragraph>
            As your insurance broker we provide a number of services to not only identify your insurance risks, but to also mitigate and
            manage them, and if the worst happens - to manage your claim to the highest service levels. Our services include:
          </Paragraph>
          <List>
            <li>New business quoting and advice</li>
            <li>Renewal negotiations</li>
            <li>Policy comparisons</li>
            <li>Excess negotiations</li>
            <li>Claims management</li>
            <li>Risk recommendations</li>
          </List>
        </>
      )}
    </Content>
  </Page>
);

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useGA4React } from 'ga-4-react';
import { useAuth } from 'auth';

function Tracker() {
  const ga4 = useGA4React();
  const {
    authState: { loggedIn, user, pdfGeneration },
  } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (loggedIn && token && ga4) {
      ga4.gtag('event', 'login', { method: 'credentials', userId: user.userId, user_email: user.email });
    }
  }, [loggedIn, user]);

  useEffect(() => {
    if (pdfGeneration > 0 && ga4) {
      ga4.gtag('event', 'pdf_generated', { userId: user.userId, user_email: user.email });
    }
  }, [pdfGeneration, user]);

  return null;
}

export default Tracker;

import React from 'react';
import { Heading, Indent, Paragraph, TitledParagraph } from 'pages/Report/components/Styled';

export const PleasureCraftUninsuredRisks = ({ recommendedCovers = [], cover }) => {
  const UninsuredRisks = () => (
    <>
      {!!recommendedCovers?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {recommendedCovers?.includes('AccessoriesModificationsPleasureCraft') && (
              <TitledParagraph>
                <Heading level={3}>Accessories/Modifications to the Pleasure Craft</Heading>
                <Paragraph>
                  Any non-standard accessories and/or modifications need to be specified on your policy. Please provide our office with the
                  items to be listed and their individual values.
                </Paragraph>
                <Paragraph>
                  Please note that when you make a claim for loss or damages, you will be required to provide proof of ownership and of its
                  value/s or your claim may not be paid.
                </Paragraph>
                <Paragraph>
                  Please note some insurers include an accessory sub-limit in their cover. Please refer to the Product Disclosure Statement
                  (PDS) for further accessory and limit information.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('StorageOfBoatMooring') && (
              <TitledParagraph>
                <Heading level={3}>Storage of Boat/Mooring</Heading>
                <Paragraph>
                  You must inform our office if the normal storage or mooring location changes or if there is a material change in the use
                  of your Pleasure Craft.
                </Paragraph>
                <Paragraph>Please refer to your policy schedule for storage or mooring information.</Paragraph>
                <Paragraph>
                  The Insurer may include terms and conditions in the policy depending on how the Pleasure Craft is stored.
                </Paragraph>
                <Paragraph>
                  If the storage mooring information or use of the Pleasure Craft is incorrect on your policy schedule you may not be
                  covered in the event of a claim.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('Water-skiingLiabilityAndAquaplaningActivities') && (
              <TitledParagraph>
                <Heading level={3}>Water-skiing Liability and Aquaplaning Activities</Heading>
                <Paragraph>
                  You must inform our office as soon as possible if the Pleasure Craft is to be involved with any water-skiing or
                  aquaplaning activities while your Pleasure Craft is being used. Some insurers may not accept cover based on these
                  activities being performed.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('RacingAndCompetitiveUse') && (
              <TitledParagraph>
                <Heading level={3}>Racing and Competitive Use</Heading>
                <Paragraph>
                  The insurer must be advised if the Pleasure Craft will be involved in any racing or competitive activities, and the
                  insurer must agree to cover in writing. Some insurers may not accept cover based on these activities being participated
                  in.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <UninsuredRisks />;
};

export const PleasureCraftImportantItemsToConsider = ({ importantItemsToConsider = [], cover }) => {
  const ImportantItemsToConsider = () => (
    <>
      {!!importantItemsToConsider?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {importantItemsToConsider?.includes('Maintenance') && (
              <TitledParagraph>
                <Heading level={3}>Maintenance</Heading>
                <Paragraph>
                  You must ensure that your Pleasure Craft, Trailer, Equipment and Accessories are maintained and in a good state of repair
                  and condition. Any loss or damage caused by poor maintenance will not be covered under the policy.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('DutyOfDisclosure') && (
              <TitledParagraph>
                <Heading level={3}>Duty of Disclosure</Heading>
                <Paragraph>
                  We refer you to &apos;Your Duty of Disclosure&apos; located on Page 2 of your policy schedule and which is also contained
                  in your PDS. Should you need to declare anything to the insurer or have any other queries please contact us on{' '}
                  <strong>(08) 8943 1200</strong>.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <ImportantItemsToConsider />;
};

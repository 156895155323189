/* eslint-disable no-shadow */
import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { CheckboxGroup, FormTitle } from 'pages/Question/components/Styled';
import { renewalCovers, personalLinesUninsuredRisksOptions } from 'pages/Question/MasterRenewal/data';
import { useReportState } from 'context';

export const MasterRenewalUninsuredRisks = ({ options, formName }) => {
  const { recommendedCoversOptions = [], itemsToConsiderOptions = [] } = options;
  const { reportState } = useReportState();
  const { selectedCommercialLinesCovers = [], selectedPersonalLineCovers = [] } = reportState?.masterRenewal?.covers;

  return (
    <>
      <FormTitle>{formName === 'strata' ? 'Uninsured Risks' : 'Recommendation'}</FormTitle>
      {!!selectedCommercialLinesCovers.length && (
        <Form.Item
          name={['uninsuredRisks', 'businessPackRecommendedCovers']}
          label="Select recommended business pack covers - commercial lines"
        >
          <CheckboxGroup>
            <Row gutter={[10, 20]}>
              {options.businessPackRecommendedCoverOptions.map((item) => (
                <Col key={item.value} xs={24} sm={12} md={8}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </CheckboxGroup>
        </Form.Item>
      )}

      {!!selectedCommercialLinesCovers.length && (
        <Form.Item name={['uninsuredRisks', 'recommendedCovers']} label="Select recommended covers - commercial lines">
          <CheckboxGroup>
            <Row gutter={[10, 20]}>
              {recommendedCoversOptions.map((item) => (
                <Col key={item.value} xs={24} sm={12} md={8}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </CheckboxGroup>
        </Form.Item>
      )}

      {!!selectedPersonalLineCovers.length &&
        selectedPersonalLineCovers.map((cover) => (
          <Form.Item
            key={cover}
            name={['uninsuredRisks', cover, 'recommendedCovers']}
            label={`Select recommended covers - personal lines - ${renewalCovers[cover]}`}
          >
            <CheckboxGroup>
              <Row gutter={[10, 20]}>
                {personalLinesUninsuredRisksOptions[cover]?.recommendedCoversOptions.map((item) => (
                  <Col key={item.value} xs={24} sm={12} md={8}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </CheckboxGroup>
          </Form.Item>
        ))}

      <FormTitle>Important Items to Consider</FormTitle>
      {!!selectedCommercialLinesCovers.length && (
        <Form.Item name={['uninsuredRisks', 'importantItemsToConsider']} label="Select items to consider/remember - commercial lines">
          <CheckboxGroup>
            <Row gutter={[10, 20]}>
              {itemsToConsiderOptions.map((item) => (
                <Col key={item.value} xs={24} sm={12} md={8}>
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </CheckboxGroup>
        </Form.Item>
      )}

      {!!selectedPersonalLineCovers.length &&
        selectedPersonalLineCovers.map((cover) => (
          <Form.Item
            key={cover}
            name={['uninsuredRisks', cover, 'importantItemsToConsider']}
            label={`Select items to consider/remember - personal lines - ${renewalCovers[cover]}`}
          >
            <CheckboxGroup>
              <Row gutter={[10, 20]}>
                {personalLinesUninsuredRisksOptions[cover]?.itemsToConsiderOptions.map((item) => (
                  <Col key={item.value} xs={24} sm={12} md={8}>
                    <Checkbox value={item.value}>{item.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </CheckboxGroup>
          </Form.Item>
        ))}
    </>
  );
};

/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { Checkbox, Form, Input, Row, Radio } from 'antd';
import { Currency } from 'components';
import {
  CenteredCol,
  CheckboxGroup,
  FormTitle,
  Variation,
  VariationContainer,
  FormTitleWrapper,
  PremiumTotalContainer,
} from 'pages/Question/components/Styled';
import { renewalCovers, personalLinesCovers } from 'pages/Question/MasterRenewal/data';
import { CustomCheckboxGroup } from 'pages/Question/components/CustomCheckBox';
import { OtherPremiumDynamicFields, PremiumDynamicFields } from 'pages/Question/components/DynamicFields';
import { useReportState } from 'context';

export const MasterRenewalPremiums = ({ options, cover, showInsuredName, showOnlySummary = false }) => {
  const { reportState } = useReportState();
  const { increaseReasonOptions, decreaseReasonOptions, reportTypeOptions, personalLinesPremiumOptions } = options;
  const [renewalPremiums, setRenewalPremiums] = useState([]);
  const [totalRenewalPremiums, setTotalRenewalPremiums] = useState([]);
  const [lastYearPremiums, setLastYearPremiums] = useState([]);
  const [totalLastYearPremiums, setTotalLastYearPremiums] = useState([]);
  const [premiumDifference, setPremiumDifference] = useState(0);
  const [reportCategory, setReportCategory] = useState(reportState?.masterRenewal?.reportType);

  return (
    <>
      {showInsuredName && (
        <>
          <Form.Item name="insuredName" label="Insured Name" rules={[{ required: true, whitespace: true }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="reportType" label="Is this a renewal or quote?" rules={[{ required: true, whitespace: true }]}>
            <Radio.Group options={reportTypeOptions} onChange={(e) => setReportCategory(e.target.value)} />
          </Form.Item>
        </>
      )}

      {!showOnlySummary && (
        <>
          <FormTitleWrapper>
            <FormTitle>{`${renewalCovers[cover]} - Premiums`}</FormTitle>
          </FormTitleWrapper>

          {personalLinesCovers[cover] ? (
            <>
              <Form.Item
                label="Renewed with"
                name={['premiums', cover, 'renewal']}
                rules={[{ required: !renewalPremiums?.length, message: 'Please add premium' }]}
              >
                <CustomCheckboxGroup
                  options={personalLinesPremiumOptions}
                  premiumTotal={totalRenewalPremiums}
                  formName={['premiums', cover, 'validation', 'renewal']}
                  type="personalLine"
                />
              </Form.Item>
              <Form.Item label="Add other insurers">
                <OtherPremiumDynamicFields
                  premiums={renewalPremiums}
                  premiumTotal={totalRenewalPremiums}
                  formName={['premiums', cover, 'renewalOther']}
                />
              </Form.Item>
              {!Number.isNaN(totalRenewalPremiums) && (
                <PremiumTotalContainer>
                  <strong>Renewal Premium Total</strong>
                  <Variation>
                    <strong>
                      <Currency value={totalRenewalPremiums} />
                    </strong>
                  </Variation>
                </PremiumTotalContainer>
              )}
              {reportCategory !== 'quote' && (
                <>
                  <Form.Item
                    label="Last Year's Premium"
                    name={['premiums', cover, 'lastYear']}
                    rules={[
                      {
                        required: !lastYearPremiums?.length,
                        message: 'Please add premium',
                      },
                    ]}
                  >
                    <CustomCheckboxGroup
                      options={personalLinesPremiumOptions}
                      premiumTotal={totalLastYearPremiums}
                      formName={['premiums', cover, 'validation', 'lastYear']}
                      type="personalLine"
                    />
                  </Form.Item>
                  <Form.Item label="Add other insurers">
                    <OtherPremiumDynamicFields
                      premiums={lastYearPremiums}
                      premiumTotal={totalLastYearPremiums}
                      formName={['premiums', cover, 'lastYearOther']}
                    />
                  </Form.Item>
                  {!Number.isNaN(totalLastYearPremiums) && (
                    <PremiumTotalContainer>
                      <strong>Lastyear Premium Total</strong>
                      <Variation>
                        <strong>
                          <Currency value={totalLastYearPremiums} />
                        </strong>
                      </Variation>
                    </PremiumTotalContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Form.Item label="Renewed with">
                <PremiumDynamicFields
                  premiumTotal={totalRenewalPremiums}
                  premiums={renewalPremiums}
                  formName={['premiums', cover, 'renewal']}
                />
              </Form.Item>
              {reportCategory !== 'quote' && (
                <Form.Item label="Last Year's Premium">
                  <PremiumDynamicFields
                    premiumTotal={totalLastYearPremiums}
                    premiums={lastYearPremiums}
                    formName={['premiums', cover, 'lastYear']}
                  />
                </Form.Item>
              )}
            </>
          )}

          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.premiums[cover]?.renewal !== curValues.premiums[cover]?.renewal ||
              prevValues.premiums[cover]?.renewalOther !== curValues.premiums[cover]?.renewalOther ||
              prevValues.premiums[cover]?.lastYear !== curValues.premiums[cover]?.lastYear ||
              prevValues.premiums[cover]?.lastYear !== curValues.premiums[cover]?.lastYearOther
            }
          >
            {({ getFieldValue }) => {
              const renewal = getFieldValue(['premiums', cover, 'renewal']);
              const renewalOther = getFieldValue(['premiums', cover, 'renewalOther']);
              const lastYear = getFieldValue(['premiums', cover, 'lastYear']);
              const lastYearOther = getFieldValue(['premiums', cover, 'lastYearOther']);

              const renewalSum = renewal
                ? renewal.reduce((acc, premium) => {
                    if (!premium?.premium) return acc;
                    return acc + premium?.premium;
                  }, 0)
                : 0;
              const lastyearSum = lastYear
                ? lastYear.reduce((acc, premium) => {
                    if (!premium?.premium) return acc;
                    return acc + premium?.premium;
                  }, 0)
                : 0;

              if (renewalOther?.length > 0) {
                const renewalOtherSum = renewalOther?.reduce((acc, premium) => {
                  if (!premium?.premium) return acc;
                  return acc + premium?.premium;
                }, 0);
                setRenewalPremiums(renewalOther);
                setTotalRenewalPremiums(renewalSum + renewalOtherSum);
              } else {
                if (renewal) {
                  setRenewalPremiums(renewal);
                  setTotalRenewalPremiums(renewalSum);
                }
              }

              if (lastYearOther?.length > 0) {
                const lastYearOtherSum = lastYearOther?.reduce((acc, premium) => {
                  if (!premium?.premium) return acc;
                  return acc + premium?.premium;
                }, 0);
                setLastYearPremiums(lastYearOther);
                setTotalLastYearPremiums(lastyearSum + lastYearOtherSum);
              } else {
                setLastYearPremiums(lastYear);
                setTotalLastYearPremiums(lastyearSum);
              }

              const variation = totalRenewalPremiums - totalLastYearPremiums;

              const variationPercent = `${
                Number.isNaN(variation) || !totalLastYearPremiums ? 'N/A' : `${((variation / totalLastYearPremiums) * 100).toFixed(2)}%`
              }`;

              setPremiumDifference(variation);

              return (
                reportCategory !== 'quote' && (
                  <VariationContainer>
                    <strong>Variation</strong>
                    <Variation>
                      <strong>
                        <Currency value={variation} />
                      </strong>
                      {variationPercent}
                    </Variation>
                  </VariationContainer>
                )
              );
            }}
          </Form.Item>
          <Form.Item label="Summary/Explanation">
            {premiumDifference > 0 ? (
              <>
                <p>The premium increase is due to:</p>
                <Form.Item name={['premiums', cover, 'increaseReason']}>
                  <CheckboxGroup>
                    <Row gutter={[10, 20]} align="top">
                      {increaseReasonOptions.map((item) => (
                        <CenteredCol key={item.value} xs={24} sm={12} md={8}>
                          <Checkbox value={item.value}>{item.label}</Checkbox>
                        </CenteredCol>
                      ))}
                    </Row>
                  </CheckboxGroup>
                </Form.Item>
              </>
            ) : (
              <>
                <p>The premium decrease is due to:</p>
                <Form.Item name={['premiums', cover, 'decreaseReason']}>
                  <CheckboxGroup>
                    <Row gutter={[10, 20]} align="top">
                      {decreaseReasonOptions.map((item) => (
                        <CenteredCol key={item.value} xs={24} sm={12} md={8}>
                          <Checkbox value={item.value}>{item.label}</Checkbox>
                        </CenteredCol>
                      ))}
                    </Row>
                  </CheckboxGroup>
                </Form.Item>
              </>
            )}
          </Form.Item>
          <Form.Item name={['premiums', cover, 'comments']} label="Comments">
            <Input.TextArea size="large" autoSize={{ minRows: 4 }} placeholder="Add a comment..." />
          </Form.Item>
        </>
      )}

      {showOnlySummary && (
        <Form.Item name={['premiums', 'overallSummaryComments']} label="Overall Summary">
          <Input.TextArea size="large" autoSize={{ minRows: 10 }} placeholder="Add a comment..." />
        </Form.Item>
      )}
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import { useUserProfile } from 'context';
import { Signature } from 'components';
import { UserLabel, Notification } from 'pages/Question/components/Styled';

export const Sign = () => {
  const [loadingUserProfile, setLoadingUserProfile] = useState(false);
  const [loadingUserSignature, setLoadingUserSignature] = useState(false);
  const { userProfile, userSignature, fetchUserProfile, fetchUserSignature, updateUserSignature } = useUserProfile();

  useEffect(() => {
    const fetchUserProfileData = async () => {
      try {
        setLoadingUserProfile(true);
        await fetchUserProfile();
      } finally {
        setLoadingUserProfile(false);
      }
    };
    fetchUserProfileData();
  }, []);

  useEffect(() => {
    const fetchUserSignatureData = async () => {
      try {
        setLoadingUserSignature(true);
        await fetchUserSignature();
      } finally {
        setLoadingUserSignature(false);
      }
    };
    fetchUserSignatureData();
  }, []);

  const handleUploadSignature = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      setLoadingUserSignature(true);
      await updateUserSignature(formData);
      onSuccess(null, file);
    } catch (err) {
      onError();
    } finally {
      setLoadingUserSignature(false);
    }
  };

  return (
    <>
      <Notification>Profile details not showing? Please update using the link at the top of this page.</Notification>
      <Form.Item label="Name">
        <UserLabel>{loadingUserProfile ? <Spin /> : userProfile?.person_name}</UserLabel>
      </Form.Item>
      <Form.Item label="Position">
        <UserLabel> {loadingUserProfile ? <Spin /> : userProfile?.job_title}</UserLabel>
      </Form.Item>
      <Form.Item label="Signature">
        {loadingUserSignature ? <Spin /> : <Signature src={userSignature} editable onUpload={handleUploadSignature} />}
      </Form.Item>
    </>
  );
};

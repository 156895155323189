import React from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { CheckboxGroup, FormTitle } from 'pages/Question/components/Styled';
import { CustomCheckboxGroup } from 'pages/Question/components/CustomCheckBox';
import { ReasonDynamicFields, AlternationQuoteDynamicFields } from 'pages/Question/components/DynamicFields';

export const AlternateQuotation = ({ options }) => {
  const { alternateQuotationPremiumOptions, reasonOptions, alternateQuotationDeclineOptions, declineOptions } = options;

  return (
    <>
      <FormTitle>Alternate Quotation/s</FormTitle>
      <Form.Item name={['alternateQuotation', 'premium']} label="Alternate Quotation Premium">
        <CustomCheckboxGroup options={alternateQuotationPremiumOptions} />
      </Form.Item>
      <Form.Item shouldUpdate={(prevValues, curValues) => prevValues?.alternateQuotation !== curValues?.alternateQuotation}>
        {({ getFieldValue }) => {
          const altQuotes = getFieldValue(['alternateQuotation', 'premium']);
          const noOtherAltQuote = altQuotes?.find((q) => q.value === alternateQuotationPremiumOptions[2].value);
          if (noOtherAltQuote && noOtherAltQuote.checked) {
            return null;
          }
          return <AlternationQuoteDynamicFields formName={['alternateQuotation', 'premiumOther']} inputType="altQuote" />;
        }}
      </Form.Item>
      <Form.Item name={['alternateQuotation', 'reason']} label="Reason">
        <CheckboxGroup>
          <Row gutter={[10, 20]}>
            {reasonOptions.map((item) => (
              <Col key={item.value} xs={24} sm={12} md={8}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
      </Form.Item>

      <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.otherImposedExcess !== curValues.otherImposedExcess}>
        {({ getFieldValue, setFieldsValue }) => (
          <ReasonDynamicFields
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            formName={['alternateQuotation', 'otherReason']}
          />
        )}
      </Form.Item>

      {alternateQuotationDeclineOptions && alternateQuotationDeclineOptions.length > 0 && (
        <Form.Item name={['alternateQuotation', 'decline']} label="Did any insurer decline to offer quotation?">
          <CustomCheckboxGroup options={alternateQuotationDeclineOptions} type="decline" />
        </Form.Item>
      )}

      {declineOptions && declineOptions.length > 0 && (
        <Form.Item name={['alternateQuotation', 'declineReason']} label="Decline Reason">
          <CustomCheckboxGroup options={declineOptions} type="decline" layout="horizontal" />
        </Form.Item>
      )}

      <Form.Item name={['alternateQuotation', 'comments']} label="Comments">
        <Input.TextArea size="large" autoSize={{ minRows: 4 }} placeholder="Add a comment..." />
      </Form.Item>
    </>
  );
};

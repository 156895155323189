import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from 'ga-4-react';
import App from './App';
import reportWebVitals from './reportWebVitals';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const ga4 = new GA4React(trackingId);

(async () => {
  try {
    if (ga4) {
      await ga4.initialize();
    }
  } finally {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

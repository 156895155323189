/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styles';
import { useReportState, useUserProfile } from 'context';
import { Download, Spinner } from 'components';
import { Article } from 'pages/Report/components/Styled';
import {
  About,
  Cover,
  AboutMe,
  MarketConditions,
  OverallPremiums,
  Sign,
  ValueProposition,
  MasterRenewalUninsuredRisks,
} from 'pages/Report/components/Sections';
import { downloadReport } from 'services';

const ButtonWrapper = styled(Space)`
  position: fixed;
  bottom: 40px;
  right: 40px;

  @media print {
    display: none;
  }
`;

const FORM_NAME = 'masterRenewal';
const REPORT_TITLE = 'MGA Darwin Renewal Presentation';

export const MasterRenewal = () => {
  const location = useLocation();
  const isPersonalLineMasterForm = location.pathname.includes('personal-lines-master-renewal');
  const redirectRoute = isPersonalLineMasterForm ? '/personal-lines-master-renewal' : '/master-renewal';
  const [loading, setLoading] = useState(true);
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [loadingSignature, setLoadingSignature] = useState(true);
  const { reportState } = useReportState();
  const { userProfile, userAvatar, userSignature, fetchUserProfile, fetchUserAvatar, fetchUserSignature } = useUserProfile();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await fetchUserProfile();
      } finally {
        setLoading(false);
      }
    };
    const fetchAvatar = async () => {
      try {
        await fetchUserAvatar();
      } finally {
        setLoadingAvatar(false);
      }
    };
    const fetchSignature = async () => {
      try {
        await fetchUserSignature();
      } finally {
        setLoadingSignature(false);
      }
    };
    fetchProfile();
    fetchAvatar();
    fetchSignature();
  }, []);
  if (!reportState?.masterRenewal?.finished) {
    return <Redirect to={redirectRoute} />;
  }

  const handleDownload = () => {
    const cover = (
      <Article>
        <Cover insuredName={reportState.masterRenewal.insuredName} title={REPORT_TITLE} />
        <About />
      </Article>
    );
    const content = (
      <Article>
        <ValueProposition formName={FORM_NAME} />
        <MarketConditions formName={FORM_NAME} />
        <AboutMe formName={FORM_NAME} userProfile={userProfile} userAvatar={userAvatar} />
        <OverallPremiums formName={FORM_NAME} reportState={reportState.masterRenewal} />
        <MasterRenewalUninsuredRisks formName={FORM_NAME} reportState={reportState.masterRenewal.uninsuredRisks} />
        <Sign
          formName={FORM_NAME}
          userSignature={userSignature}
          userProfile={userProfile}
          reportType={reportState.masterRenewal?.reportType}
        />
      </Article>
    );

    return downloadReport(content, cover, { reportState, userProfile }, FORM_NAME);
  };

  return loading || loadingAvatar || loadingSignature ? (
    <Spinner />
  ) : (
    <Article>
      <Cover insuredName={reportState.masterRenewal.insuredName} title={REPORT_TITLE} />
      <About />
      <ValueProposition formName={FORM_NAME} />
      <MarketConditions formName={FORM_NAME} />
      <AboutMe formName={FORM_NAME} userProfile={userProfile} userAvatar={userAvatar} />
      <OverallPremiums formName={FORM_NAME} reportState={reportState.masterRenewal} />
      <MasterRenewalUninsuredRisks formName={FORM_NAME} reportState={reportState.masterRenewal.uninsuredRisks} />
      <Sign
        formName={FORM_NAME}
        userSignature={userSignature}
        userProfile={userProfile}
        reportType={reportState.masterRenewal?.reportType}
      />
      <ButtonWrapper direction="vertical" size={20}>
        <Link to={redirectRoute}>
          <Button size="large" block>
            Back
          </Button>
        </Link>
        <Download onDownload={handleDownload} fileName="MasterRenewalReport.pdf">
          {(download, downloading) => (
            <Button size="large" type="primary" icon={<DownloadOutlined />} onClick={download} loading={downloading}>
              Download PDF
            </Button>
          )}
        </Download>
      </ButtonWrapper>
    </Article>
  );
};

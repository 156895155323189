import React, { useState } from 'react';
import { Checkbox, Form, Input, Row, Radio } from 'antd';
import { Currency } from 'components';
import { CenteredCol, CheckboxGroup, FormTitle, Variation, VariationContainer, FormTitleWrapper } from 'pages/Question/components/Styled';
import { PremiumDynamicFields } from 'pages/Question/components/DynamicFields';

export const Premiums = ({ options }) => {
  const { increaseReasonOptions, decreaseReasonOptions, reportType } = options;
  const [premiumDifference, setPremiumDifference] = useState(0);
  const [renewalPremiums, setRenewalPremiums] = useState([]);
  const [lastYearPremiums, setLastYearPremiums] = useState([]);

  return (
    <>
      <Form.Item name="insuredName" label="Insured Name" rules={[{ required: true, whitespace: true }]}>
        <Input size="large" />
      </Form.Item>
      {reportType && (
        <Form.Item name="reportType" label="Is this a renewal or quote?" rules={[{ required: true, whitespace: true }]}>
          <Radio.Group options={reportType} />
        </Form.Item>
      )}
      <FormTitleWrapper>
        <FormTitle>Premiums</FormTitle>
      </FormTitleWrapper>
      <Form.Item label="Renewed with">
        <PremiumDynamicFields premiums={renewalPremiums} formName={['premiums', 'renewal']} />
      </Form.Item>
      <Form.Item label="Last Year's Premium">
        <PremiumDynamicFields premiums={lastYearPremiums} formName={['premiums', 'lastYear']} />
      </Form.Item>

      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.premiums?.renewal !== curValues.premiums?.renewal || prevValues.premiums?.lastYear !== curValues.premiums?.lastYear
        }
      >
        {({ getFieldValue }) => {
          const renewal = getFieldValue(['premiums', 'renewal']);
          const lastYear = getFieldValue(['premiums', 'lastYear']);

          setRenewalPremiums(renewal);
          setLastYearPremiums(lastYear);

          const sumLastYearPremium = lastYear?.reduce((acc, premium) => {
            if (!premium?.premium) return acc;
            return acc + premium?.premium;
          }, 0);
          const variation =
            renewal?.reduce((acc, premium) => {
              if (!premium?.premium) return acc;
              return acc + premium?.premium;
            }, 0) - sumLastYearPremium;

          const variationPercent = `${
            Number.isNaN(variation) || !sumLastYearPremium ? 'N/A' : `${((variation / sumLastYearPremium) * 100).toFixed(2)}%`
          }`;
          setPremiumDifference(variation);
          return (
            <VariationContainer>
              <strong>Variation</strong>
              <Variation>
                <strong>
                  <Currency value={variation} />
                </strong>
                {variationPercent}
              </Variation>
            </VariationContainer>
          );
        }}
      </Form.Item>
      <Form.Item label="Summary/Explanation">
        {premiumDifference > 0 ? (
          <>
            <p>The premium increase is due to:</p>
            <Form.Item name={['premiums', 'increaseReason']} label="Field" noStyle>
              <CheckboxGroup>
                <Row gutter={[10, 20]} align="top">
                  {increaseReasonOptions.map((item) => (
                    <CenteredCol key={item.value} xs={24} sm={12} md={8}>
                      <Checkbox value={item.value}>{item.label}</Checkbox>
                    </CenteredCol>
                  ))}
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </>
        ) : (
          <>
            <p>The premium decrease is due to:</p>
            <Form.Item name={['premiums', 'decreaseReason']} label="Field" noStyle>
              <CheckboxGroup>
                <Row gutter={[10, 20]} align="top">
                  {decreaseReasonOptions.map((item) => (
                    <CenteredCol key={item.value} xs={24} sm={12} md={8}>
                      <Checkbox value={item.value}>{item.label}</Checkbox>
                    </CenteredCol>
                  ))}
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item name={['premiums', 'comments']} label="Comments">
        <Input.TextArea size="large" autoSize={{ minRows: 4 }} placeholder="Add a comment..." />
      </Form.Item>
    </>
  );
};

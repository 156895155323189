import React from 'react';
import { ConfigProvider } from 'antd';
import { GlobalStyle, ThemeProvider } from 'styles';
import { AuthProvider } from 'auth';
import { ReportStateProvider, UserProfileProvider } from 'context';
import Routes from './routes';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required.',
};

const App = () => (
  <ConfigProvider form={{ validateMessages }}>
    <ThemeProvider>
      <GlobalStyle />
      <AuthProvider>
        <UserProfileProvider>
          <ReportStateProvider>
            <Routes />
          </ReportStateProvider>
        </UserProfileProvider>
      </AuthProvider>
    </ThemeProvider>
  </ConfigProvider>
);

export default App;

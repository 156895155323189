import React, { useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { useReportState } from 'context';
import { Layout } from 'components';
import { FormButtons, FormContainer } from 'pages/Question/components/Styled';
import { AlternateQuotation, CurrentExcesses, Premiums, Sign, UninsuredRisks } from 'pages/Question/components/Forms';
import { options } from './data';

const questionForms = [
  { name: 'strataPremiums', component: <Premiums options={options.premiums} /> },
  { name: 'strataCurrentExcesses', component: <CurrentExcesses options={options.currentExcesses} /> },
  { name: 'strataAlternateQuotation', component: <AlternateQuotation options={options.alternateQuotation} /> },
  { name: 'strataUninsuredRisks', component: <UninsuredRisks options={options.uninsuredRisks} formName="strata" /> },
  { name: 'strataSign', component: <Sign /> },
];

const DEFAULT_VALUES = {
  premiums: { renewal: [{ insurer: '' }], lastYear: [{ insurer: '' }] },
  uninsuredRisks: {
    recommendedCovers: [
      'OfficeBearersLiability',
      'MachineryBreakdown',
      'VoluntaryWorkers',
      'FloodCover',
      'StormSurge',
      'CatastropheCover',
      'LossOfRentTemporaryAccommodation',
      'FidelityGuarantee',
    ],
    importantItemsToConsider: [
      'FireSafetyEquipment',
      'PreventativeMaintenance',
      'CyclonePreparedness',
      'RoofInspections',
      'BuildingValuations',
      'LeakDetection',
      'OfficeBearersLiability',
    ],
  },
};

export const Strata = () => {
  const history = useHistory();
  const { reportState, formIndex, save, prevForm, nextForm, setFormIndex, questionSet, setQuestionSet } = useReportState();

  useLayoutEffect(() => {
    if (questionSet !== 'strata' || !questionForms[formIndex]) {
      setQuestionSet('strata');
      setFormIndex(0);
    }
  }, [questionSet, setQuestionSet, setFormIndex, formIndex]);

  useEffect(
    () => () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    },
    [formIndex],
  );

  const currentForm = questionForms[formIndex];

  let initialValues;
  if (reportState?.strata?.premiums?.renewal && reportState?.strata?.premiums?.renewal?.length > 0) {
    initialValues = reportState.strata;
  } else {
    initialValues = DEFAULT_VALUES;
  }

  return (
    <Layout>
      <FormContainer>
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            forms[name].validateFields();
            if (name === questionForms[questionForms.length - 1].name) {
              save({ strata: { ...reportState.strata, ...values, finished: true } });
              history.push('/strata-report');
            } else {
              save({ strata: { ...reportState?.strata, ...values } });
              nextForm();
            }
          }}
        >
          <Form
            name={currentForm?.name && currentForm.name}
            colon={false}
            layout="vertical"
            requiredMark={false}
            initialValues={initialValues}
          >
            {currentForm?.component && currentForm.component}
            <FormButtons
              nextText={formIndex === questionForms.length - 1 ? 'SUBMIT' : 'NEXT'}
              prevProps={{ ghost: true }}
              onPrev={formIndex === 0 ? undefined : prevForm}
            />
          </Form>
        </Form.Provider>
      </FormContainer>
    </Layout>
  );
};

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Layout as AntLayout, Popconfirm } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import styled from 'styles';
import { useAuth } from 'auth';
import { useReportState } from 'context';
import { Logo } from 'components';

const { Header, Content } = AntLayout;

const StyledHeader = styled(Header)`
  height: 80px;
  padding: 0 ${({ theme }) => theme.size.spacing.xxxl}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.paper[0]};
  box-shadow: 0 1px 3px 1px ${({ theme }) => theme.palette.greys[25]};
`;

const LinkButton = styled(Button)`
  margin-right: ${({ theme }) => theme.size.spacing.xl}px;
`;

const Banner = styled.div`
  height: 80px;
  padding: 0 ${({ theme }) => theme.size.spacing.xxxl}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.branding.primary.light};
`;

const PersonName = styled.span`
  font-family: var(--text-font);
  font-size: var(--font-size-h4);
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.size.spacing.xxxl}px;
`;

export const Layout = ({ children }) => {
  const { authState, logout } = useAuth();
  const location = useLocation();
  const { reset, setFormIndex } = useReportState();

  const handleLogout = () => {
    logout();
    reset();
  };

  const handleResetForm = () => {
    reset();
    window.location.reload();
  };

  return (
    <AntLayout>
      <StyledHeader>
        <Link to="/">
          <Logo height={54} />
        </Link>
        {authState.loggedIn && (
          <div>
            <a
              href={`${process.env.REACT_APP_LMI_RISKCOACH}account/user-profile?token=${authState.token}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkButton size="large" type="primary" ghost>
                User Profile
              </LinkButton>
            </a>
            <Link to="/">
              <LinkButton size="large" type="primary" ghost onClick={() => setFormIndex(0)}>
                Form Selection
              </LinkButton>
            </Link>
            <Button ghost size="large" type="primary" icon={<LogoutOutlined />} onClick={handleLogout}>
              Logout
            </Button>
          </div>
        )}
      </StyledHeader>
      <Content>
        {authState.loggedIn && (
          <Banner>
            <PersonName>Hello, {authState.user.personName}</PersonName>
            {location.pathname !== '/' && (
              <Popconfirm title={<div>Are you sure to clear all forms?</div>} onConfirm={handleResetForm} okText="Yes" cancelText="No">
                <Button>Reset Forms</Button>
              </Popconfirm>
            )}
          </Banner>
        )}
        <Container>{children}</Container>
      </Content>
    </AntLayout>
  );
};

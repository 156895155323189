import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { fetchUser, loginAsync } from './AuthService';

const TOKEN_KEY = 'token';

const setAxiosHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY;
  axios.defaults.headers.common.Accept = 'application/json;v=2.0';
};

const removeAxiosHeader = () => {
  delete axios.defaults.headers.common.Authorization;
  delete axios.defaults.headers.common['x-api-key'];
  delete axios.defaults.headers.common.Accept;
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: undefined,
    user: undefined,
    loggedIn: false,
    loading: true,
    validating: false,
    pdfGeneration: 0,
  });

  const handleLogin = async (token) => {
    try {
      localStorage.setItem(TOKEN_KEY, token);
      setAxiosHeader(token);
      const user = await fetchUser();
      setAuthState({ token, user, loggedIn: true, loading: false, validating: false, pdfGeneration: 0 });
    } catch (error) {
      setAuthState({ token: undefined, user: undefined, loggedIn: false, loading: false, validating: false, pdfGeneration: 0 });
      throw new Error(error);
    }
  };

  const login = useCallback(
    async (data) => {
      try {
        setAuthState({ ...authState, validating: true });
        const res = await loginAsync(data);
        await handleLogin(res.access_token);
        return Promise.resolve(res.access_token);
      } catch (error) {
        setAuthState({ ...authState, validating: false });
        return Promise.reject(error);
      }
    },
    [authState],
  );

  const logout = async () => {
    try {
      localStorage.removeItem(TOKEN_KEY);
      removeAxiosHeader();
      setAuthState({ token: undefined, user: undefined, loggedIn: false, loading: false, validating: false, pdfGeneration: 0 });
    } catch (error) {
      throw new Error(error);
    }
  };

  const pdfDownload = useCallback(() => {
    setAuthState({ ...authState, pdfGeneration: authState.pdfGeneration + 1 });
  }, [authState]);

  useEffect(() => {
    const bootstrapAsync = async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        await handleLogin(token);
      } else {
        await logout();
      }
      axios.interceptors.response.use(
        (res) => res,
        async ({ response }) => {
          switch (response?.status) {
            case StatusCodes.UNAUTHORIZED:
              logout();
            // eslint-disable-next-line no-fallthrough
            default:
              return Promise.reject(response);
          }
        },
      );
    };

    bootstrapAsync();
  }, []);

  const value = useMemo(() => ({ authState, login, logout, pdfDownload }), [pdfDownload, authState, login]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled from 'styles';
import { useReportState, useUserProfile } from 'context';
import { Download, Spinner } from 'components';
import { Article } from 'pages/Report/components/Styled';
import {
  About,
  Cover,
  AboutMe,
  MarketConditions,
  Premiums,
  Sign,
  UninsuredRisks,
  ValueProposition,
} from 'pages/Report/components/Sections';
import { downloadReport } from 'services';

const ButtonWrapper = styled(Space)`
  position: fixed;
  bottom: 40px;
  right: 40px;

  @media print {
    display: none;
  }
`;

const FORM_NAME = 'workerCompensation';
const REPORT_TITLE = "MGA Darwin Worker's Compensation Presentation";

export const WorkerCompensation = () => {
  const [loading, setLoading] = useState(true);
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [loadingSignature, setLoadingSignature] = useState(true);
  const { reportState } = useReportState();
  const { userProfile, userAvatar, userSignature, fetchUserProfile, fetchUserAvatar, fetchUserSignature } = useUserProfile();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserProfile();
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserAvatar();
      } finally {
        setLoadingAvatar(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserSignature();
      } finally {
        setLoadingSignature(false);
      }
    };
    fetchData();
  }, []);

  if (!reportState?.workerCompensation?.finished) {
    return <Redirect to="/worker-compensation" />;
  }

  const handleDownload = () => {
    const cover = (
      <Article>
        <Cover insuredName={reportState.workerCompensation.insuredName} title={REPORT_TITLE} />
        <About />
      </Article>
    );
    const content = (
      <Article>
        <ValueProposition formName={FORM_NAME} />
        <MarketConditions formName={FORM_NAME} />
        <AboutMe formName={FORM_NAME} userProfile={userProfile} userAvatar={userAvatar} />
        <Premiums formName={FORM_NAME} reportState={reportState.workerCompensation} />
        <UninsuredRisks formName={FORM_NAME} reportState={reportState.workerCompensation.uninsuredRisks} />
        <Sign
          formName={FORM_NAME}
          userSignature={userSignature}
          userProfile={userProfile}
          reportType={reportState.workerCompensation.reportType}
        />
      </Article>
    );

    return downloadReport(content, cover, { reportState, userProfile }, FORM_NAME);
  };

  return loading || loadingAvatar || loadingSignature ? (
    <Spinner />
  ) : (
    <Article>
      <Cover insuredName={reportState.workerCompensation.insuredName} title={REPORT_TITLE} />
      <About />
      <ValueProposition formName={FORM_NAME} />
      <MarketConditions formName={FORM_NAME} />
      <AboutMe formName={FORM_NAME} userProfile={userProfile} userAvatar={userAvatar} />
      <Premiums formName={FORM_NAME} reportState={reportState.workerCompensation} />
      <UninsuredRisks formName={FORM_NAME} reportState={reportState.workerCompensation.uninsuredRisks} />
      <Sign
        formName={FORM_NAME}
        userSignature={userSignature}
        userProfile={userProfile}
        reportType={reportState.workerCompensation.reportType}
      />
      <ButtonWrapper direction="vertical" size={20}>
        <Link to="/worker-compensation">
          <Button size="large" block>
            Back
          </Button>
        </Link>
        <Download onDownload={handleDownload} fileName="WorkersCompensationReport.pdf">
          {(download, downloading) => (
            <Button size="large" type="primary" icon={<DownloadOutlined />} onClick={download} loading={downloading}>
              Download PDF
            </Button>
          )}
        </Download>
      </ButtonWrapper>
    </Article>
  );
};

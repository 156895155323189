import { createGlobalStyle } from './styledComponents';
import './fonts.scss';

export const GlobalStyle = createGlobalStyle`
  :root {
    --text-font: 'ITC Avant Garde Gothic Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --heading-font: 'Anivers', Tahoma, Arial, Helvetica, sans-serif;
    --font-size-h1: 1.383rem;
    --font-size-h2: 1.296rem;
    --font-size-h3: 1.215rem;
    --font-size-h4: 1.138rem;
    --font-size-h5: 1.067rem;
    --font-size-body: 1rem;
    --font-size-small: 0.937rem;
  }

  html {
    font-size: 14px;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--text-font);
    line-height: 1.75;
  }

  p {
    margin: 0;
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--heading-font);
    font-weight: 400;
    margin: 3rem 0 1.38rem;
    line-height: 1.2;
  }

  h1 {
    font-size: var(--font-size-h1);
    margin-top: 0;
  }

  h2 {
    font-size: var(--font-size-h2);
  }

  h3 {
    font-size: var(--font-size-h3);
  }

  h4 {
    font-size: var(--font-size-h4);
  }

  h5 {
    font-size: var(--font-size-h5);
  }

  small {
    font-size: var(--font-size-small);
  }

  @media (min-width: 320px) {
    html {
      font-size: calc(14px + (22 - 14) * (100vw - 320px) / (1200 - 320));
    }
  }

  @media (min-width: 1200px) {
    html {
      font-size: 22px;
    }
  }

  .ant-notification-notice {
    padding: 24px 16px;
    width: 320px;
  }

  .ant-notification-notice-close {
    top: 24px;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 0;
    margin-left: 40px;
  }

  .ant-btn-lg {
    > span {
      padding-top: 2px;
    }
  }
`;

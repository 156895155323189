import React from 'react';
import { Avatar } from 'antd';
import { PhoneFilled, MobileFilled, MailFilled, EnvironmentFilled, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Content, Page, PageTitle, List, Table, Heading, TableBody, TableCell, TableRow } from 'pages/Report/components/Styled';

const Info = styled.span`
  margin-left: 10px;
`;

const ProfileList = styled(List)`
  padding: 0;
  line-height: 2;
`;

const ProfileListItem = styled.li`
  list-style-type: none;
`;

const UserPosition = styled.div`
  font-size: 16px;
`;

const HtmlTableCell = styled(TableCell)`
  line-height: 2;
`;

export const AboutMe = ({ userProfile, userAvatar, formName }) => (
  <Page dynamic formName={formName}>
    <PageTitle>About Me</PageTitle>
    <Content>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={250}>
              <Avatar icon={<UserOutlined />} size={200} src={userAvatar} />
            </TableCell>
            <HtmlTableCell>
              <Heading level={2} style={{ marginBottom: 0 }}>
                {userProfile?.person_name}
              </Heading>
              {userProfile?.job_title && <UserPosition>{userProfile?.job_title}</UserPosition>}
              {userProfile?.company && <Heading level={3}>{userProfile?.company}</Heading>}

              <ProfileList>
                <ProfileListItem>
                  <PhoneFilled /> <Info>{userProfile?.phone}</Info>
                </ProfileListItem>
                <ProfileListItem>
                  <MobileFilled /> <Info>{userProfile?.mobile}</Info>
                </ProfileListItem>
                <ProfileListItem>
                  <MailFilled /> <Info>{userProfile?.email}</Info>
                </ProfileListItem>
                <ProfileListItem>
                  <EnvironmentFilled /> <Info>{userProfile?.address}</Info>
                </ProfileListItem>
              </ProfileList>
            </HtmlTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {userProfile?.description && (
        <Table>
          <TableBody>
            <TableRow>
              <HtmlTableCell dangerouslySetInnerHTML={{ __html: userProfile.description }} />
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Content>
  </Page>
);

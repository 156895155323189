import React from 'react';
import { Heading, List, Paragraph, TitledParagraph, Indent } from 'pages/Report/components/Styled';

export const LandlordUninsuredRisks = ({ recommendedCovers = [], cover }) => {
  const UninsuredRisks = () => (
    <>
      {!!recommendedCovers?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {recommendedCovers?.includes('ContentsInsurance') && (
              <TitledParagraph>
                <Heading level={3}>Contents Insurance</Heading>
                <Paragraph>
                  There is currently no cover for Contents under this policy. Things such as curtains, blinds, carpets, and standalone ovens
                  are all considered Contents. It is recommended a nominal amount is insured even if there are no typical Contents items
                  within the residence.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ContentsSpecialValuables') && (
              <TitledParagraph>
                <Heading level={3}>Contents - Specified Contents, Special Contents and/or Valuables</Heading>
                <Paragraph>
                  There are policy limits for certain items, these will need to be specifically listed along with their value to ensure full
                  cover.
                </Paragraph>
                <Paragraph>
                  <strong>Please refer to the attached Product Disclosure Statement for policy limits and information.</strong>
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('DisasterCover') && (
              <TitledParagraph>
                <Heading level={3}>Disaster Cover</Heading>
                <Paragraph>
                  Should a significant event or catastrophe occur, the cost of building materials and labour may increase due to high demand
                  for these services. Disaster Cover with TIO offers up to an additional 30% of your Building Sum Insured should the cost of
                  repairing or replacing your building exceed your sum insured following loss or damage caused by a named cyclone (including
                  storm surge), flood or earthquake (including tsunami).
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('RentDefaultTheftByTenant') && (
              <TitledParagraph>
                <Heading level={3}>Rent Default and Theft by Tenant</Heading>
                <Paragraph>
                  You can increase your coverage under the Landlords Insurance Policy by adding the optional cover of Rent Default and Theft
                  by Tenant section. Additional premiums will apply when adding this optional cover.
                </Paragraph>
                <Paragraph>
                  This covers money the tenant is required to pay if the tenant;
                  <List>
                    <li>
                      leaves the rental property before the end of the tenancy period or written rental agreement, without giving notice
                    </li>
                    <li>is legally evicted from the rental property</li>
                    <li>stops paying rent owed to you</li>
                    <li>
                      causes loss or damages to your building or contents arising from theft by the tenant during the period of insurance
                    </li>
                  </List>
                </Paragraph>
                <Paragraph>
                  Please note this optional cover is only available if there is a written rental agreement between you and the tenant which
                  specifically states the length of the rental period, the weekly rent payable and the amount of the bond.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <UninsuredRisks />;
};

export const LandlordImportantItemsToConsider = ({ importantItemsToConsider = [], cover }) => {
  const ImportantItemsToConsider = () => (
    <>
      {!!importantItemsToConsider?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {importantItemsToConsider?.includes('BuildingSumInsuredCalculator') && (
              <TitledParagraph>
                <Heading level={3}>Building Sum Insured Calculator</Heading>
                <Paragraph>
                  It is recommended your nominated Sum Insured values are enough to cover your estimated Building and/or Contents
                  replacement value. You can use a Building Sum Insured calculator to help determine how much it would cost to replace your
                  home here: <br />
                  <a href="https://sumsure.cordell.com.au/#/products/7/profiles/112" target="_blank" rel="noreferrer">
                    https://sumsure.cordell.com.au/#/products/7/profiles/112
                  </a>
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('CircumstanceChanges') && (
              <TitledParagraph>
                <Heading level={3}>Circumstance Changes</Heading>
                <Paragraph>
                  In addition to your duty of disclosure, there are other obligations that you must meet under the Insurance Contract with
                  the insurer.
                </Paragraph>
                <Paragraph>
                  You must inform our office as soon as possible if your circumstances change;
                  <List>
                    <li>Property has been unoccupied for a continuous period of 60 days</li>
                    <li>
                      No longer renting/leasing the property and you now occupy it as your home or are or using the property as a holiday
                      home
                    </li>
                    <li>If you are undertaking renovations, extensions or demolition to the value of $75,000 (inc. GST) or more</li>
                    <li>The people who are insured under the policy changes</li>
                  </List>
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('CyclonePreparedness') && (
              <TitledParagraph>
                <Heading level={3}>Cyclone Preparedness</Heading>
                <Paragraph>
                  It is important that you have a cyclone plan in place. The Northern Territory Government, the Northern Territory Police,
                  Fire and Emergency Services have action plans and guides available on their respective websites.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('FireSafetyEquipmentSmokeAlarms') && (
              <TitledParagraph>
                <Heading level={3}>Fire Safety Equipment (Smoke Alarms)</Heading>
                <Paragraph>It is important that fire safety equipment is regularly inspected and follows all relevant codes.</Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PaymentInformationMGAEzipay') && (
              <TitledParagraph>
                <Heading level={3}>Payment Information (MGA Ezipay)</Heading>
                <Paragraph>
                  If your monthly payment details have not changed, your policy will renew automatically and no further action is required.
                </Paragraph>
                <Paragraph>
                  There is a 4.4% administration fee charged to utilise the monthly direct debit facility. This fee is included in the
                  overall Broker Fee noted on the invoice.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PaymentInformationHunterPremiumFundingPreviousYearRollOver') && (
              <TitledParagraph>
                <Heading level={3}>Payment Information (Hunter Premium Funding Previous Year/Rollover)</Heading>
                <Paragraph>
                  As per the previous year, your premium is being paid by monthly instalments through Hunter Premium Funding.
                </Paragraph>
                <Paragraph>
                  Please see attached your new Hunter Premium Funding Contract for this insurance renewal for your review and completion.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PaymentInformationHunterPremiumFundingNewMonthlyContract') && (
              <TitledParagraph>
                <Heading level={3}>Payment Information (Hunter Premium Funding - New Monthly Contract)</Heading>
                <Paragraph>
                  As this policy renewal is over the allowed contract limit of $5000, MGA Pay By The Month can no longer be offered. In lieu
                  of this, please see attached a Hunter Premium Funding quote for your review. Hunter Premium Funding is an external company
                  that is able to facilitate monthly instalments for larger premiums. Please complete and return this application should you
                  wish to proceed with this option.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PolicyLimits') && (
              <TitledParagraph>
                <Heading level={3}>Policy Limits</Heading>
                <Paragraph>
                  Please ensure you review the attached PDS and make note of any policy limits. Items outside of these limits may not be
                  covered unless specified on your policy.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PreventativeMaintenance') && (
              <TitledParagraph>
                <Heading level={3}>Preventative Maintenance</Heading>
                <Paragraph>
                  It is important to ensure regular building monitoring and maintenance is undertaken to mitigate any potential loss or
                  damage.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('ExcessOptions') && (
              <TitledParagraph>
                <Heading level={3}>Excess Options</Heading>
                <Paragraph>
                  You do have the option to increase the basic excess on the policy which may reduce the payable premium slightly. However,
                  in the event of a claim the higher excess selected will be payable. Please contact our office if you would like to explore
                  this option.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <ImportantItemsToConsider />;
};

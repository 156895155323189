import React from 'react';
import styled from 'styles';

const StyledPercentage = styled.div`
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  font-weight: 700;
  text-align: center;
  padding: 7px ${({ theme }) => theme.size.spacing.sm}px;
`;

export const Percentage = ({ value, total }) => {
  const percent = (value / total) * 100;

  if (!Number.isNaN(percent)) return <StyledPercentage>{Math.round(percent)}%</StyledPercentage>;
  return null;
};

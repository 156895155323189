export const options = {
  premiums: {
    reportType: [
      {
        label: 'renewal',
        value: 'renewal',
      },
      {
        label: 'quote',
        value: 'quote',
      },
    ],
    increaseReasonOptions: [
      {
        label: 'rate increases in line with current market conditions',
        value: 'rate increases in line with current market conditions',
      },
      { label: 'previous claims made on your policy', value: 'previous claims made on your policy' },
      { label: 'ongoing claims made on your policy', value: 'ongoing claims made on your policy' },
      { label: 'increase in number of vehicles and/or plant/equipment', value: 'increase in number of vehicles and/or plant/equipment' },
    ],
    decreaseReasonOptions: [
      {
        label: 'removal of vehicles and/or plant/equipment',
        value: 'removal of vehicles and/or plant/equipment',
      },
      { label: 'increase in excess', value: 'increase in excess' },
      { label: 'reduction in coverage', value: 'reduction in coverage' },
      {
        label: 'we have successfully negotiated a reduction in premium',
        value: 'we have successfully negotiated a reduction in premium',
      },
    ],
  },
  alternateQuotation: {
    alternateQuotationPremiumOptions: [
      { label: 'TIO', value: 'TIO' },
      { label: 'GT', value: 'GT' },
      { label: 'CGU', value: 'CGU' },
      { label: 'QBE', value: 'QBE' },
      { label: 'Other', value: 'Other' },
      { label: 'No alternative quotations sought', value: 'No alternative quotations sought' },
    ],
    reasonOptions: [
      { label: 'due to having concerns with their claims service', value: 'concerns with their claims service' },
      { label: 'premium not competitive', value: 'premium not competitive' },
    ],
    alternateQuotationDeclineOptions: [
      { label: 'TIO', value: 'TIO' },
      { label: 'GT', value: 'GT' },
      { label: 'Other', value: 'DeclineOther' },
    ],
    declineOptions: [
      { label: 'previous claims history', value: 'previous claims history' },
      { label: 'ongoing/active claims', value: 'ongoing/active claims' },
      { label: 'unrepaired damage', value: 'unrepaired damage' },
      { label: 'driver history', value: 'driver history' },
      { label: 'underwriting guidelines', value: 'underwriting guidelines' },
    ],
  },
  unisuredRisks: {
    recommendedCoversOptions: [
      { label: 'Loss of Use', value: 'LossOfUse' },
      { label: 'Hire Car Following an Accident', value: 'HireCarFollowingAccident' },
      { label: 'Hired in Plant and Equipment', value: 'HiredInPlantAndEquipment' },
    ],
    itemsToConsiderOptions: [
      { label: 'Accessories and Modifications', value: 'AccessoriesAndModifications' },
      { label: 'Additional Excesses Applicable', value: 'AdditionalExcessesApplicable' },
      { label: 'Own Vehicle Damage', value: 'OwnVehicleDamage' },
      { label: 'Radius Limit', value: 'RadiusLimit' },
      { label: 'Vehicle Sums Insured', value: 'VehicleSumsInsured' },
      { label: 'Fleet Listings', value: 'FleetListings' },
    ],
  },
};

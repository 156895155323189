import React from 'react';
import styled from 'styles';

const StyledHeading = styled.h1`
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  font-weight: 700;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.size.spacing.xxl}px;

  @media print {
    page-break-after: avoid;
  }
`;

export const Heading = ({ level, ...rest }) => <StyledHeading as={`h${level}`} {...rest} />;

Heading.defaultProps = {
  level: 1,
};

export const PageTitle = styled(Heading).attrs({ level: 1 })`
  background-color: ${({ theme }) => theme.palette.branding.primary.normal};
  color: ${({ theme }) => theme.palette.paper[0]};
  line-height: 2.75;

  &::before {
    counter-increment: page;
    content: counter(page) '.';
    display: inline-block;
    width: 80px;
    text-align: center;
  }
`;

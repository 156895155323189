import styled, { css } from 'styles';

export const Table = styled.table`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size.spacing.xxxl}px;
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  font-size: 14px;
`;

export const TableHead = styled.thead`
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
  h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const TableBody = styled.tbody`
  h1,
  h2,
  h3 {
    margin-bottom: ${({ theme }) => theme.size.spacing.sm}px;
  }
`;

export const TableRow = styled.tr`
  border-bottom: 2px solid ${({ theme }) => theme.palette.branding.primary.normal};
`;

const baseCell = css`
  padding: ${({ theme }) => theme.size.spacing.md}px ${({ theme }) => theme.size.spacing.xl}px;
  text-align: ${({ align }) => align};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${({ highlight }) =>
    highlight &&
    css`
      background-color: ${({ theme }) => theme.palette.branding.primary.light};
    `}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ compact }) =>
    compact &&
    css`
      padding: ${({ theme }) => theme.size.spacing.xs}px ${({ theme }) => theme.size.spacing.xs}px;
    `}
`;

export const TableHeadCell = styled.th`
  ${baseCell}
`;

export const TableCell = styled.td`
  ${baseCell}
`;

import React from 'react';
import { Heading, Paragraph, TitledParagraph, Indent } from 'pages/Report/components/Styled';

export const MotorVehicleUninsuredRisks = ({ recommendedCovers = [], cover }) => {
  const UninsuredRisks = () => (
    <>
      {!!recommendedCovers?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {recommendedCovers?.includes('AccessoriesModificationsVehicle') && (
              <TitledParagraph>
                <Heading level={3}>Accessories/Modifications to the Vehicle</Heading>
                <Paragraph>
                  Any non-standard accessories and/or modifications need to be specified on your policy. Please provide our office with the
                  items to be listed and their individual values.
                </Paragraph>
                <Paragraph>
                  Please note that when you make a claim for loss or damages, you will be required to provide proof of ownership and of its
                  value/s or your claim may not be paid.
                </Paragraph>
                <Paragraph>
                  Please note some insurers include an accessory sub-limit in their cover. Please refer to the Product Disclosure Statement
                  (PDS) for further accessory and limit information.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('DriverHistory') && (
              <TitledParagraph>
                <Heading level={3}>Driver History</Heading>
                <Paragraph>
                  Per &apos;Your Duty of Disclosure&apos; located on page 2 of your attached policy schedule, if there are any changes to
                  your driver history or if you need to declare anything to the insurer, please contact our office as soon as possible.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('UndeclaredUnnamedDrivers') && (
              <TitledParagraph>
                <Heading level={3}>Undeclared/Unnamed Drivers</Heading>
                <Paragraph>Some insurers impose additional excesses for undeclared or unnamed drivers.</Paragraph>
                <Paragraph>Please refer to your policy schedule for your named driver details and excess information.</Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('YoungInexperiencedDrivers') && (
              <TitledParagraph>
                <Heading level={3}>Young/Inexperienced Drivers</Heading>
                <Paragraph>There may be additional excesses applicable for young or inexperienced drivers.</Paragraph>
                <Paragraph>
                  Please refer to your policy schedule for the excess information for your vehicle/s and refer to the Product Disclosure
                  Statement for further information.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <UninsuredRisks />;
};

export const MotorVehicleImportantItemsToConsider = ({ importantItemsToConsider = [], cover }) => {
  const ImportantItemsToConsider = () => (
    <>
      {!!importantItemsToConsider?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {importantItemsToConsider?.includes('CarHireFollowingAccident') && (
              <TitledParagraph>
                <Heading level={3}>Car Hire Following an Accident</Heading>
                <Paragraph>
                  An Optional Cover to include Hire Car Following an Accident can be included at an additional premium when Comprehensive
                  cover has been taken.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('RoadsideAssistanceBZIOnly') && (
              <TitledParagraph>
                <Heading level={3}>Roadside Assistance (BZI Only)</Heading>
                <Paragraph>
                  Blue Zebra Insurance offers Roadside Assistance as an Optional Cover. If you have selected Comprehensive cover you may be
                  able to add Blue Zebra Insurance Roadside Assistance for an additional premium.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('DutyOfDisclosure') && (
              <TitledParagraph>
                <Heading level={3}>Duty of Disclosure</Heading>
                <Paragraph>
                  We refer you to &apos;Your Duty of Disclosure&apos; located on Page 2 of your policy schedule and which is also contained
                  in your PDS. Should you need to declare anything to the insurer or have any other queries please contact us on
                  <strong>(08) 8943 1200</strong>.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('ExcessInformation') && (
              <TitledParagraph>
                <Heading level={3}>Excess Information</Heading>
                <Paragraph>
                  Please refer to the Policy Schedule noting the Excesses that apply to this Motor Vehicle Insurance Cover.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <ImportantItemsToConsider />;
};

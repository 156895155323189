import React from 'react';
import { Content, Figure, PageTitle, Page, Paragraph } from 'pages/Report/components/Styled';
import insuranceCycle from 'assets/insurance_cycle.jpg';

export const MarketConditions = ({ formName }) => (
  <Page dynamic formName={formName}>
    <PageTitle>Market Conditions</PageTitle>
    <Content>
      <Figure src={insuranceCycle} />
      <Paragraph>
        The insurance market is cyclical, moving through both hard and soft markets. In a soft cycle/market, insurers provide lower
        premiums/rates and have a more relaxed approach to assessing the risk of insuring a business. In a hard market it is quite the
        opposite, as insurers increase rates/premiums and provide reduced/restricted coverage as they adopt a tougher underwriting approach.
      </Paragraph>
      <Paragraph>
        Currently, the insurance market is moving through a Hard Market. This has been caused by a number of factors including years of
        declining prices, falling investment returns due to historical low interest rates, increases in frequency and severity of losses,
        and the rising cost of reinsurance. Premiums and excesses on policies are increasing, and underwriting guidelines are tightening.
        Insurers will be less likely to provide insurance coverage for clients with high-risk profiles, poor claims history or clients with
        little or no risk management.
      </Paragraph>
      <Paragraph>
        A string of recent natural disasters including severe bushfires, cyclones, floods and hailstorms, has seen Insurers pay the highest
        levels of claims witnessed in recent periods. The COVID-19 pandemic and its aftermath are also expected to continue hitting some
        property-casualty lines harder than others, and Business Interruption claims will continue to be a concern. The ultimate cost of the
        pandemic remains unclear and the full economic implications of COVID-19 are yet to be realised.
      </Paragraph>
    </Content>
  </Page>
);

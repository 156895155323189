import { Checkbox, Col, Radio, Input, Form, Space } from 'antd';
import styled, { css } from 'styles';

export const FormContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;

  .ant-form-item-label {
    padding: 10px 0;

    > label {
      color: ${({ theme }) => theme.palette.greys[50]};
      font-size: 18px;
      font-weight: bold;
    }
  }
`;
export const FormTitleWrapper = styled(Form.Item)`
  margin-bottom: 0;
`;

export const FormTitle = styled.h3`
  margin-top: ${({ theme }) => theme.size.spacing.xxxl}px;
`;

const Group = css`
  width: 100%;
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  ${Group}
`;

export const RadioGroup = styled(Radio.Group)`
  ${Group}
`;

export const CustomGroup = styled.div`
  ${Group}
`;

export const Label = styled.div`
  min-width: 196px;
  @media (max-width: 768px) {
    min-width: 90px;
  }
`;

export const UserLabel = styled.div`
  font-size: 16px;
`;

export const CenteredCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const TextInput = styled(Input)`
  min-width: 196px;
  @media (max-width: 768px) {
    min-width: 90px;
  }
`;

export const ButtonContainer = styled(Space)`
  margin-top: ${({ theme }) => theme.size.spacing.xxl}px;
  display: flex;
  justify-content: ${({ position }) => (!position ? 'center' : position)};
`;

export const HiddenFormItem = styled(Form.Item)`
  margin-bottom: 0px;
  .ant-form-item-control-input {
    min-height: 0px;
  }
`;

export const Notification = styled.div`
  background-color: ${({ theme, warning }) => (warning ? theme.palette.danger : theme.palette.branding.primary.light)};
  font-size: 16px;
  margin: 12px 0;
  padding: 20px;
`;

export const FormStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VariationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.spacing.md}px ${({ theme }) => theme.size.spacing.lg}px;
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  background-color: ${({ theme }) => theme.palette.branding.primary.light};
`;

export const Variation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PremiumTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.spacing.md}px ${({ theme }) => theme.size.spacing.lg}px;
  margin-bottom: ${({ theme }) => theme.size.spacing.xl}px;
  width: 400px;
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  background-color: ${({ theme }) => theme.palette.branding.primary.light};
`;

export const StyledSpace = styled(Space)`
  .ant-form-item-has-error {
    .ant-input-number-disabled {
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      &:hover {
        background-color: #f5f5f5;
        border-color: #d9d9d9;
      }
    }
  }
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    margin-left: 240px;
  }
`;

/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styles';
import { Currency } from 'components';
import {
  Content,
  Heading,
  Page,
  PageTitle,
  Paragraph,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  List,
} from 'pages/Report/components/Styled';
import { NoAlternativeQuotationSought, renewalCovers } from 'pages/Question/MasterRenewal/data';
import { newLineTextFormatter } from './OverallPremiums';

const CommentsHeading = styled(Heading).attrs({ level: 3 })`
  margin-bottom: 0;
  line-height: 2.4;
`;

const FormattedList = styled(List)`
  line-height: 2;
`;

const Star = styled.sup`
  top: 0;
  font-size: 14px;
  ::after {
    content: '*';
  }
`;

const joinFormatter = (arrayToJoin, array2ToJoin) => {
  if (array2ToJoin && array2ToJoin?.length > 0) {
    if (arrayToJoin) {
      return [...arrayToJoin, ...array2ToJoin].reduce((previous, current, index, array) =>
        index === array.length - 1 ? `${previous} and ${current}` : `${previous}, ${current}`,
      );
    }
    return array2ToJoin.reduce((previous, current, index, array) =>
      index === array.length - 1 ? `${previous} and ${current}` : `${previous}, ${current}`,
    );
  }
  return arrayToJoin.reduce((previous, current, index, array) =>
    index === array.length - 1 ? `${previous} and ${current}` : `${previous}, ${current}`,
  );
};

export const Premiums = ({ formName, reportState, cover }) => {
  const { premiums, alternateQuotation, currentExcesses = [], reportType } = reportState;

  const renewal = premiums?.renewalOther?.length
    ? premiums?.renewal
      ? [...premiums.renewal, ...premiums.renewalOther]
      : premiums.renewalOther
    : premiums.renewal;
  const lastYear = premiums?.lastYearOther?.length
    ? premiums?.lastYear
      ? [...premiums.lastYear, ...premiums.lastYearOther]
      : premiums.lastYearOther
    : premiums.lastYear;
  const sumRenewalPremium = renewal.reduce((acc, premium) => acc + premium?.premium, 0);
  const sumLastYearPremium = lastYear && lastYear.reduce((acc, premium) => acc + premium?.premium, 0);
  const variation = sumRenewalPremium - sumLastYearPremium;
  const variationPercent = `${
    Number.isNaN(variation) || !sumLastYearPremium ? 'N/A' : `${((variation / sumLastYearPremium) * 100).toFixed(2)}%`
  }`;

  const { reason = [], otherReason = [], premiumOther: altPremiumOther = [], premium: altQuotesPremiums = [] } = alternateQuotation;
  const altQuotes = [...altQuotesPremiums, ...altPremiumOther?.map((p) => ({ label: p.insurer, value: p.insurer, amount: p.premium }))];
  const otherReasons = otherReason.filter((other) => other?.checked).map((other) => other?.reason) || [];
  const reasons = [...reason, ...otherReasons];

  const { imposedExcess = [], otherImposedExcess = [] } = currentExcesses;
  const otherimposedExcesses = otherImposedExcess.map((excess) => excess?.otherImposedExcess).filter((excess) => excess?.checked) || [];
  const imposedExcesses = [...imposedExcess, ...otherimposedExcesses];

  return (
    <>
      <Page dynamic formName={formName}>
        <PageTitle>{cover ? `${renewalCovers[cover]}` : 'Premiums'}</PageTitle>
        <Content>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell colSpan={2}>
                  <Heading level={2}>{reportType !== 'quote' ? 'Premiums' : 'Quoted Premium'}</Heading>
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{lastYear && 'Renewal'} Premium</TableCell>
                <TableCell align="right">
                  <Currency value={sumRenewalPremium} />
                </TableCell>
              </TableRow>
              {lastYear && (
                <>
                  <TableRow>
                    <TableCell>Last Year&apos;s Premium</TableCell>
                    <TableCell align="right">
                      <Currency value={sumLastYearPremium} />
                      <Star />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Variation ($)</TableCell>
                    <TableCell align="right" bold>
                      <Currency value={variation} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Variation (%)</TableCell>
                    <TableCell align="right" bold>
                      {variationPercent}
                    </TableCell>
                  </TableRow>
                </>
              )}
              {reportType !== 'quote' && (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    *includes any alterations made to the policy throughout the year
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>
                  <Heading level={2}>Summary</Heading>
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell highlight>
                  <Paragraph>
                    We have {lastYear ? 'renewed' : 'sought'} your policy with{' '}
                    <strong>
                      {joinFormatter(
                        premiums?.renewal?.map((premium) => premium.insurer),
                        premiums?.renewalOther?.map((premium) => premium.insurer),
                      )}
                    </strong>
                    .
                    {lastYear && (
                      <>
                        Your {formName === 'strata' ? 'policy' : 'premium'} has
                        {variation > 0 ? ' increased ' : ' decreased '}
                        <strong>
                          {variation > 0 ? variationPercent : `${Math.abs((variation / sumLastYearPremium) * 100).toFixed(2)}%`}{' '}
                        </strong>
                        this year.
                      </>
                    )}
                    {variation > 0 && premiums.increaseReason?.length > 0 && (
                      <>
                        {` `}This premium {variation > 0 ? 'increase' : 'decrease'} is due to{' '}
                        <strong>{joinFormatter(premiums.increaseReason)}</strong>.
                      </>
                    )}
                    {variation < 0 && premiums.decreaseReason?.length > 0 && (
                      <>
                        {` `}This premium decrease is due to <strong>{joinFormatter(premiums.decreaseReason)}</strong>.
                      </>
                    )}
                  </Paragraph>
                  <Paragraph>{newLineTextFormatter(premiums.comments)}</Paragraph>
                  <Paragraph>
                    For detailed policy coverage regarding {formName === 'strata' && 'sums insured, excesses and'} other important
                    information, please refer to your {reportType === 'quote' ? 'Quote' : 'Tax Invoice'}/Coverage Summary.
                  </Paragraph>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Content>
      </Page>

      {(formName === 'strata' || !!alternateQuotation.premium?.length) && (
        <Page dynamic formName={formName}>
          <Content>
            {formName === 'strata' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell colSpan={2}>
                      <Heading level={2}>Current Excesses / Imposed Terms and Conditions</Heading>
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Standard Excess</TableCell>
                    <TableCell align="right" bold>
                      <Currency value={currentExcesses.standardExcess} precision={0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Imposed Excess/es</TableCell>
                    <TableCell align="right" bold>
                      {imposedExcesses?.length > 0
                        ? imposedExcesses.map((item) => (
                            <div key={item.value}>
                              {item.value} <Currency value={item.amount} precision={0} />
                            </div>
                          ))
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                  {(currentExcesses.comments?.trim() || currentExcesses.totalOccupancyGreaterThan75P) && (
                    <TableRow>
                      <TableCell colSpan={2} highlight>
                        <CommentsHeading>Comments</CommentsHeading>
                        <Paragraph>{newLineTextFormatter(currentExcesses.comments)}</Paragraph>
                        {currentExcesses.strataType === 'Commercial' ? (
                          <Paragraph>Occupancy as advised</Paragraph>
                        ) : (
                          currentExcesses.totalOccupancyGreaterThan75P && (
                            <Paragraph>
                              Please note we have based these renewal terms on a total occupancy of the complex to be greater than 75%. If
                              this is incorrect, please notify us immediately as failure to do so may result in a change of terms and
                              conditions by the insurer.
                            </Paragraph>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}

            {(altQuotes?.length > 0 ||
              alternateQuotation.comments?.trim() ||
              (alternateQuotation.decline?.length > 0 && alternateQuotation.declineReason?.length > 0)) && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell colSpan={2}>
                      <Heading level={2}>Alternate Quotation/s</Heading>
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {altQuotes &&
                    altQuotes.map(
                      (item) =>
                        item.value !== NoAlternativeQuotationSought && (
                          <TableRow key={item.value}>
                            <TableCell uppercase>{item.value === 'Other' ? item.insurerName : item.value}</TableCell>
                            <TableCell align="right" bold>
                              <Currency value={item.amount} />
                            </TableCell>
                          </TableRow>
                        ),
                    )}
                  <TableRow>
                    <TableCell colSpan={2} highlight>
                      {altQuotes.length > 0 && altQuotes.filter((option) => option.value !== NoAlternativeQuotationSought).length > 0 && (
                        <>
                          <CommentsHeading>Comments</CommentsHeading>
                          <Paragraph>
                            We have sought an alternate quotation this year from{' '}
                            <strong>
                              {altQuotes
                                .filter((item) => item.value !== NoAlternativeQuotationSought)
                                .map((item) => (item.value === 'Other' ? item.insurerName : item.value))
                                .join('/')}
                            </strong>{' '}
                            who have offered a premium of{' '}
                            {altQuotes.map(
                              (item, index) =>
                                item.value !== NoAlternativeQuotationSought && (
                                  <strong key={item.value}>
                                    <Currency value={item.amount} />
                                    {index !== altQuotes.length - 1 && '/'}
                                  </strong>
                                ),
                            )}
                            .
                            {reasons && reasons.length > 0 && (
                              <>
                                {' '}
                                Their quotation/s are not being recommended due to – <strong>{joinFormatter(reasons)}</strong>.
                              </>
                            )}
                          </Paragraph>
                        </>
                      )}

                      {altQuotes.find((option) => option.value === NoAlternativeQuotationSought) && (
                        <Paragraph>
                          Due to the strong policy wording and competitive pricing, we have taken the liberty to renew with the holding
                          insurer.
                        </Paragraph>
                      )}

                      {alternateQuotation.decline?.length > 0 && alternateQuotation.declineReason?.length > 0 && (
                        <>
                          <Paragraph>
                            <strong>
                              {alternateQuotation.decline
                                .map((item) => (item.value === 'DeclineOther' ? item.insurerName : item.value))
                                .join('/')}
                            </strong>{' '}
                            declined to offer a quotation due to{' '}
                          </Paragraph>
                          <FormattedList>
                            {alternateQuotation.declineReason.map(
                              (item) =>
                                item.value &&
                                !['other decline reason', 'construction'].includes(item.value) && <li key={item.value}>{item.value}</li>,
                            )}
                            {alternateQuotation.declineReason.map(
                              (item) => item.value === 'construction' && <li key={item.reason}>construction - {item.reason}</li>,
                            )}
                            {alternateQuotation.declineReason.map(
                              (item) => item.value === 'other decline reason' && <li key={item.reason}>other - {item.reason}</li>,
                            )}
                          </FormattedList>
                        </>
                      )}
                      {alternateQuotation?.comments && <Paragraph>{newLineTextFormatter(alternateQuotation.comments)}</Paragraph>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Content>
        </Page>
      )}
    </>
  );
};

import React from 'react';
import styled from 'styles';

const CardContainer = styled.div`
  width: ${({ width }) => width};
  margin: 40px auto;
  padding: 60px 120px;
  box-shadow: 0 1px 3px 1px ${({ theme }) => theme.palette.greys[25]};
`;

const CardTitle = styled.h1`
  margin-bottom: 60px;
  color: ${({ theme }) => theme.palette.branding.primary.normal};
  text-align: center;
`;

export const Card = ({ children, title, width = '680px' }) => (
  <CardContainer width={width}>
    {title && <CardTitle>{title}</CardTitle>}
    {children}
  </CardContainer>
);

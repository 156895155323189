import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { useAuth } from 'auth';
import { ForgetPassword, Login, FormSelection } from 'pages';
import { Strata, WorkerCompensation, CommercialMotor, MasterRenewal } from 'pages/Question';
import {
  Strata as StrataReport,
  WorkerCompensation as WorkerCompensationReport,
  CommercialMotor as CommercialMotorReport,
  MasterRenewal as MasterRenewalReport,
} from 'pages/Report';
import { Spinner } from 'components';
import Tracker from './components/Tracker/Tracker';

const PRIVATE_ROUTES = [
  { path: '/', component: <FormSelection /> },
  { path: '/strata', component: <Strata /> },
  { path: '/worker-compensation', component: <WorkerCompensation /> },
  { path: '/commercial-motor', component: <CommercialMotor /> },
  { path: '/master-renewal', component: <MasterRenewal /> },
  { path: '/personal-lines-master-renewal', component: <MasterRenewal /> },
  { path: '/strata-report', component: <StrataReport /> },
  { path: '/worker-compensation-report', component: <WorkerCompensationReport /> },
  { path: '/commercial-motor-report', component: <CommercialMotorReport /> },
  { path: '/master-renewal-report', component: <MasterRenewalReport /> },
  { path: '/personal-lines-master-renewal-report', component: <MasterRenewalReport /> },
];

const PrivateRoute = ({ children, ...rest }) => {
  const { authState } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => (authState.loggedIn ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />)}
    />
  );
};

export default () => {
  const { authState } = useAuth();

  return authState.loading ? (
    <Spinner />
  ) : (
    <Router>
      <Tracker />
      <Switch>
        {PRIVATE_ROUTES.map(({ path, component }) => (
          <PrivateRoute key={path} path={path} exact>
            {component}
          </PrivateRoute>
        ))}
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forget-password">
          <ForgetPassword />
        </Route>
        <Route>
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

import React from 'react';
import { Heading, Indent, Paragraph, TitledParagraph } from 'pages/Report/components/Styled';

export const MotorcycleUninsuredRisks = ({ cover, recommendedCovers = [] }) => {
  const UninsuredRisks = () => (
    <>
      {!!recommendedCovers?.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {recommendedCovers?.includes('AccessoriesModificationsMotorcycle') && (
              <TitledParagraph>
                <Heading level={3}>Accessories/Modifications to the Motorcycle</Heading>
                <Paragraph>
                  Any non-standard accessories and/or modifications need to be specified on your policy. Please provide our office with the
                  items to be listed and their individual values.
                </Paragraph>
                <Paragraph>
                  Please note that when you make a claim for loss or damages, you will be required to provide proof of ownership and item/s
                  values/s or your claim may not be paid.
                </Paragraph>
                <Paragraph>
                  Please note some insurers include an accessory sub-limit in their cover. Please refer to the Product Disclosure Statement
                  (PDS) for further accessory and limit information.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('DriverHistory') && (
              <TitledParagraph>
                <Heading level={3}>Driver History</Heading>
                <Paragraph>
                  Per &apos;Your Duty of Disclosure&apos; located on page 2 of your policy schedule, if there are any changes to your driver
                  history or if you need to declare anything to the insurer, please contact our office as soon as possible.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('UndeclaredUnnamedRiders') && (
              <TitledParagraph>
                <Heading level={3}>Undeclared/Unnamed Riders</Heading>
                <Paragraph>
                  Please refer to your policy summary for named rider details and excess information. Some insurers impose additional
                  excesses for undeclared or unnamed riders.
                </Paragraph>
                <Paragraph>Please contact our office if you wish to add a rider to your policy.</Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('YoungInexperiencedRiders') && (
              <TitledParagraph>
                <Heading level={3}>Young/Inexperienced Riders</Heading>
                <Paragraph>There may be additional excesses applicable for young or inexperienced riders.</Paragraph>
                <Paragraph>
                  Please refer to your policy summary for the excess information for your motorcycle and refer to the PDS for further
                  information.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <UninsuredRisks />;
};

export const MotorcycleImportantItemsToConsider = ({ importantItemsToConsider = [], cover }) => {
  const ImportantItemsToConsider = () => (
    <>
      {!!importantItemsToConsider.length && (
        <>
          <Heading level={2}>{cover}</Heading>
          <Indent>
            {importantItemsToConsider?.includes('DutyOfDisclosure') && (
              <TitledParagraph>
                <Heading level={3}>Duty of Disclosure</Heading>
                <Paragraph>
                  We refer you to &apos;Your Duty of Disclosure&apos; located on Page 2 of your policy schedule and which is also contained
                  in your PDS. Should you need to declare anything to the insurer or have any other queries please contact us on{' '}
                  <strong>(08) 8943 1200</strong>.
                </Paragraph>
              </TitledParagraph>
            )}
          </Indent>
        </>
      )}
    </>
  );

  return <ImportantItemsToConsider />;
};

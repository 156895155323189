import React from 'react';
import { Col, Row, Space } from 'antd';
import styled from 'styles';
import { Logo, Signature } from 'components';
import { Content, Page, PageTitle, Paragraph } from 'pages/Report/components/Styled';

const SignBox = styled(Content)`
  padding: ${({ theme }) => theme.size.spacing.xxxl}px;
`;

const LogoBox = styled(Space)`
  position: absolute;
  top: 630px;
  padding: 0 80px;

  ${Paragraph} {
    margin-bottom: 0;
  }
`;

const ValueBox = styled.div`
  position: absolute;
  top: 810px;
  padding: 30px 80px;
  background-color: ${({ theme }) => theme.palette.branding.primary.normal};

  ${Paragraph} {
    margin-bottom: 0;
    font-size: 11px;
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: ${({ noMargin, theme }) => (noMargin ? 0 : theme.size.spacing.xxl)}px;
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  color: ${({ inverted, theme }) => (inverted ? theme.palette.paper[0] : theme.palette.branding.primary.normal)};
  text-align: ${({ align }) => align};
`;

const Label = styled.div`
  min-width: 100px;
  font-weight: bold;
`;

const LabelItem = ({ label, children, direction, noMargin, align, inverted }) => (
  <LabelWrapper direction={direction} noMargin={noMargin} align={align} inverted={inverted}>
    <Label>{label}</Label>
    {children}
  </LabelWrapper>
);

export const Sign = ({ userProfile, userSignature, formName, reportType }) => (
  <Page formName={formName}>
    <PageTitle>Sign Off</PageTitle>
    <SignBox>
      <LabelItem label="Name">{userProfile?.person_name}</LabelItem>
      <LabelItem label="Position">{userProfile?.job_title}</LabelItem>
      <LabelItem label="Signed">
        <Signature src={userSignature} editable={false} />
      </LabelItem>
    </SignBox>
    <LogoBox size={80}>
      <Paragraph align="right">
        {formName === 'strata'
          ? `Thank you for choosing MGA as your trusted advisor, we look forward to working with your Corporation and Body Corporate Manager for the upcoming year.`
          : `Thank you for ${
              reportType === 'quote' ? 'considering' : 'choosing'
            }  MGA as your broker, we look forward to working with you for the upcoming year.`}
      </Paragraph>
      <Logo width={160} />
    </LogoBox>
    <ValueBox>
      <Row gutter={30}>
        <Col span={8}>
          <LabelItem label="We Value" direction="vertical" noMargin align="right" inverted>
            <Paragraph align="right" inverted>
              Family, Integrity, Teamwork, Loyalty, Innovation
            </Paragraph>
          </LabelItem>
        </Col>
        <Col span={8}>
          <LabelItem label="Mission Statement" direction="vertical" noMargin align="right" inverted>
            <Paragraph align="right" inverted>
              From beginning to end, we provide tailored service through quality advice and superior claims advocacy.
            </Paragraph>
          </LabelItem>
        </Col>
        <Col span={8}>
          <LabelItem label="Vision Statement" direction="vertical" noMargin align="right" inverted>
            <Paragraph align="right" inverted>
              To be the most dedicated brokerage in the NT, providing financial security through tailored service and experience.
            </Paragraph>
          </LabelItem>
        </Col>
      </Row>
    </ValueBox>
  </Page>
);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
import React, { useRef } from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { unionBy } from 'lodash';
import { Currency, CurrencyInput, Percentage } from 'components';
import { CustomGroup, Label, StyledSpace, Variation, PremiumTotalContainer } from 'pages/Question/components/Styled';
import { NoAlternativeQuotationSought } from 'pages/Question/MasterRenewal/data';

export const CustomCheckbox = ({ value, onChange, inputRef, type, options = [], formName, premiumTotal }) => {
  const insurerRef = useRef();
  const reasonRef = useRef();
  const currencyRef = useRef();

  const triggerChange = (changedValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const handleChange = (e) => {
    if (e.target.checked && type === 'personalLine') {
      triggerChange({
        checked: e.target.checked,
        insurer: insurerRef?.current?.state?.value || value.label,
        premium: currencyRef.current?.value && Number(currencyRef.current.value.replace(/[^0-9\\.-]+/g, '')),
      });
    } else {
      if (e.target.checked) {
        triggerChange({
          checked: e.target.checked,
          insurerName: insurerRef.current?.state?.value && insurerRef.current.state.value,
          reason: reasonRef.current?.state?.value && reasonRef.current.state.value,
          amount: currencyRef.current?.value && Number(currencyRef.current.value.replace(/[^0-9\\.-]+/g, '')),
        });
      } else {
        triggerChange({ checked: e.target.checked });
      }
    }
  };

  const handleAmountChange = (amount) => {
    if (type === 'personalLine') {
      triggerChange({ premium: amount });
    } else {
      triggerChange({ amount });
    }
  };

  const handleInsurerNameChange = (e) => {
    if (type === 'personalLine') {
      triggerChange({ insurer: e.target.value });
    } else {
      triggerChange({ insurerName: e.target.value });
    }
  };

  const handleReasonInputChange = (e) => {
    triggerChange({ reason: e.target.value });
  };

  if (!value) {
    triggerChange({ checked: true });
  }

  const checkBoxOptions = options.map((option) => {
    if (!['other decline reason', 'construction'].includes(option.value)) return option.value;
    return null;
  });

  return (
    <Checkbox value={value?.value} checked={value?.checked} onChange={handleChange}>
      <StyledSpace size={[20]} align="baseline">
        <Label>{value?.label || 'Description'}</Label>
        {value?.value !== NoAlternativeQuotationSought && (
          <>
            {!checkBoxOptions.includes(value?.value) && type !== 'formList' && (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={formName ? [...formName, 'reason', value.value] : ['alternateQuotation', 'validation', 'reason', value.value]}
                rules={
                  value?.checked && [
                    {
                      required: true,
                      message: 'Please add description',
                      whitespace: true,
                    },
                  ]
                }
              >
                <Input
                  size="large"
                  placeholder="Description"
                  ref={reasonRef}
                  value={value?.checked && value?.reason}
                  disabled={!value?.checked}
                  onChange={handleReasonInputChange}
                />
              </Form.Item>
            )}
            {type === 'formList' && (
              <Input
                size="large"
                placeholder="Description"
                ref={inputRef}
                value={value?.reason}
                disabled={!value?.checked}
                onChange={handleReasonInputChange}
              />
            )}

            {checkBoxOptions.includes(value?.value) && type !== 'decline' && (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={formName ? [...formName, 'amount', value.value] : ['alternateQuotation', 'validation', 'amount', value.value]}
                rules={
                  value?.checked && [
                    {
                      required: true,
                      message: 'Please add amount',
                    },
                    () => ({
                      validator(_, amount) {
                        if (amount === undefined || amount === null || amount > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Must be greater than 0'));
                      },
                    }),
                  ]
                }
              >
                <CurrencyInput
                  size="large"
                  currencyRef={currencyRef}
                  value={value?.checked ? value?.amount || 0 : undefined}
                  disabled={!value?.checked}
                  onChange={handleAmountChange}
                />
              </Form.Item>
            )}

            {['Other', 'DeclineOther', ''].includes(value?.value) && (
              <Form.Item
                style={{ marginBottom: 0 }}
                name={
                  formName ? [...formName, 'insurerName', value.value] : ['alternateQuotation', 'validation', 'insurerName', value.value]
                }
                rules={
                  value?.checked && [
                    {
                      required: true,
                      message: 'Please add insurer name',
                      whitespace: true,
                    },
                  ]
                }
              >
                <Input
                  size="large"
                  placeholder="Insurer Name"
                  value={value?.checked && value?.insurerName}
                  disabled={!value?.checked}
                  onChange={handleInsurerNameChange}
                  ref={insurerRef}
                />
              </Form.Item>
            )}

            {type === 'personalLine' && value?.checked && <Percentage value={value?.premium} total={premiumTotal} />}
          </>
        )}
      </StyledSpace>
    </Checkbox>
  );
};

export const CustomCheckboxGroup = ({ options, value, onChange, type, layout, formName, premiumTotal }) => {
  const items = options.map((o) => ({ ...value?.find((item) => item.value === o.value), ...o }));

  const handleChange = (changedValue) => {
    if (onChange) {
      if (changedValue.value === NoAlternativeQuotationSought) {
        onChange(
          changedValue.checked
            ? [{ label: NoAlternativeQuotationSought, value: NoAlternativeQuotationSought, checked: changedValue.checked }]
            : unionBy([changedValue], value, 'value'),
        );
      } else {
        onChange(
          changedValue.checked
            ? unionBy([changedValue], value, 'value').filter((changed) => changed.value !== NoAlternativeQuotationSought)
            : value.filter((item) => item.value !== changedValue.value),
        );
      }
    }
  };

  if (layout === 'horizontal') {
    return (
      <CustomGroup>
        <Row gutter={[10, 20]}>
          {items.map((item) => {
            if (!['other decline reason', 'construction'].includes(item.value)) {
              return (
                <Col key={item.value} xs={24} sm={12} md={8}>
                  <CustomCheckbox value={item} onChange={handleChange} type={type} options={options} />
                </Col>
              );
            }
            return (
              <Col key={item.value} span={24}>
                <CustomCheckbox value={item} onChange={handleChange} type={type} options={options} />
              </Col>
            );
          })}
        </Row>
      </CustomGroup>
    );
  }

  return (
    <CustomGroup>
      <Row gutter={[0, 20]}>
        {items.map((item) => (
          <Col key={item.value} span={24}>
            <CustomCheckbox
              value={item}
              onChange={handleChange}
              type={type}
              options={options}
              formName={formName}
              premiumTotal={premiumTotal}
            />
          </Col>
        ))}
      </Row>
    </CustomGroup>
  );
};

import React from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styles';
import { useAuth } from 'auth';
import { Card, Layout } from 'components';

const ForgetPassword = styled(Form.Item)`
  text-align: right;
`;

export const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState, login } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };

  const submit = async (values) => {
    try {
      await login(values);
      history.replace(from);
    } catch (errMsg) {
      message.error(errMsg);
    }
  };

  if (authState.loggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <Layout>
      <Card title="Login">
        <Form name="login" labelCol={{ span: 0 }} onFinish={submit}>
          <Form.Item name="username" label="Username" rules={[{ required: true }]}>
            <Input prefix={<UserOutlined />} size="large" placeholder="Username" spellCheck={false} />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input prefix={<LockOutlined />} size="large" type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit" loading={authState.validating}>
              LOG IN
            </Button>
          </Form.Item>
          <ForgetPassword>
            <Link to="forget-password">Forgot password?</Link>
          </ForgetPassword>
        </Form>
      </Card>
    </Layout>
  );
};

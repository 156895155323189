/* eslint-disable consistent-return */
import React, { useRef } from 'react';
import { Checkbox, Col, Form, Input, Radio, Row, Space, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { unionBy } from 'lodash';
import styled from 'styled-components';
import { CurrencyInput } from 'components';
import { CustomGroup, FormTitle, Label, RadioGroup, TextInput, HiddenFormItem } from 'pages/Question/components/Styled';

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    margin-left: 25px;
  }
`;

const StyledImposedExcessFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    margin-left: 240px;
  }
`;

const CustomCheckbox = ({ value, onChange, inputRef, fieldKey }) => {
  const triggerChange = (changedValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const handleChange = (e) => {
    triggerChange({ checked: e.target.checked });
  };

  const handleAmountChange = (newAmount) => {
    triggerChange({ amount: newAmount });
  };

  const handleInputChange = (e) => {
    triggerChange({ value: e.target.value });
  };

  if (!value && !value?.value?.includes('Water Damage')) {
    triggerChange({ checked: true });
  }

  return (
    <Checkbox value={value?.value} onChange={handleChange} checked={value?.checked}>
      <Space size={20}>
        {!value?.value?.includes('Water Damage') && fieldKey !== undefined ? (
          <TextInput
            size="large"
            placeholder="Description"
            value={value?.value}
            disabled={!value?.checked}
            onChange={handleInputChange}
            ref={inputRef}
          />
        ) : (
          <Label>{value.label}</Label>
        )}
        <>
          <CurrencyInput size="large" value={value?.amount || 0} disabled={!value?.checked} required onChange={handleAmountChange} />
        </>
      </Space>
    </Checkbox>
  );
};

const CustomCheckboxGroup = ({ options = [], value, onChange }) => {
  const items = options.map((o) => ({ ...value?.find((item) => item.value === o.value), ...o }));

  const handleChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue.checked ? unionBy([changedValue], value, 'value') : value.filter((item) => item.value !== changedValue.value));
    }
  };

  return (
    <CustomGroup>
      <Row gutter={[0, 20]}>
        {items.map((item) => (
          <Col key={item.value} span={24}>
            <CustomCheckbox value={item} onChange={handleChange} />
          </Col>
        ))}
      </Row>
    </CustomGroup>
  );
};

const ImposedExcessDynamicFields = ({ getFieldValue, setFieldsValue }) => {
  const currentValue = getFieldValue(['currentExcesses', 'otherImposedExcess']);
  const inputRef = useRef();
  const handleChange = (changedValue) => {
    setFieldsValue(
      changedValue.checked
        ? unionBy([changedValue], currentValue, 'value')
        : currentValue?.filter((item) => item?.value !== changedValue?.value),
    );
  };
  const setFocus = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.List name={['currentExcesses', 'otherImposedExcess']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <Space key={field.key} align="baseline">
              <Col span={24}>
                <StyledFormItem
                  {...field}
                  name={[field.name, 'otherImposedExcess']}
                  fieldKey={[field.fieldKey, 'otherImposedExcess']}
                  rules={[
                    {
                      required: true,
                      transform: (value) => {
                        if (!value.checked) {
                          return 'not required';
                        }
                        return value.value;
                      },
                      message: 'Please add description',
                    },
                    () => ({
                      validator(_, value) {
                        if (value.checked) {
                          if (value.value && !value.amount) {
                            return Promise.reject(new Error('Amount must be greater than 0'));
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <CustomCheckbox
                    value={currentValue?.[fields.fieldKey]}
                    onChange={handleChange}
                    inputRef={inputRef}
                    fieldKey={field.name}
                  />
                </StyledFormItem>
              </Col>
              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                add();
                setFocus();
              }}
              block
            >
              <PlusOutlined /> Add more
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export const CurrentExcesses = ({ options }) => {
  const { imposedExcessOption } = options;
  return (
    <>
      <FormTitle>Current Excesses / Imposed Terms and Conditions</FormTitle>
      <Form.Item name={['currentExcesses', 'standardExcess']} label="Standard Excess" rules={[{ required: true }]}>
        <CurrencyInput size="large" />
      </Form.Item>

      <StyledImposedExcessFormItem
        name={['currentExcesses', 'imposedExcess']}
        label="Imposed Excess"
        rules={[
          {
            required: true,
            transform: (value) => {
              if (!value) {
                return 'not checked';
              }
              if (value && !value[0]?.checked) {
                return 'not required';
              }
              if (value && value[0]?.checked && value[0]?.amount > 0) {
                return 'amount is bigger than 0';
              }
            },
            message: 'Must be greater than 0',
          },
        ]}
      >
        <CustomCheckboxGroup options={imposedExcessOption} />
      </StyledImposedExcessFormItem>

      <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.otherImposedExcess !== curValues.otherImposedExcess}>
        {({ getFieldValue, setFieldsValue }) => (
          <ImposedExcessDynamicFields getFieldValue={getFieldValue} setFieldsValue={setFieldsValue} />
        )}
      </Form.Item>

      <Form.Item name={['currentExcesses', 'strataType']} label="Strata type?">
        <RadioGroup>
          <Row gutter={[10, 20]}>
            <Col xs={24} sm={6}>
              <Radio value="Residential">Residential</Radio>
            </Col>
            <Col xs={24} sm={6}>
              <Radio value="Commercial">Commercial</Radio>
            </Col>
          </Row>
        </RadioGroup>
      </Form.Item>

      <HiddenFormItem
        shouldUpdate={(prevValues, curValues) => prevValues.currentExcesses?.strataType !== curValues.currentExcesses?.strataType}
      >
        {({ getFieldValue }) => {
          const strataType = getFieldValue(['currentExcesses', 'strataType']);
          if (strataType === 'Residential')
            return (
              <Form.Item
                name={['currentExcesses', 'totalOccupancyGreaterThan75P']}
                label="Total occupancy greater than 75%"
                initialValue={false}
              >
                <RadioGroup>
                  <Row gutter={[10, 20]}>
                    <Col xs={24} sm={6}>
                      <Radio value={false}>No</Radio>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Radio value>Yes</Radio>
                    </Col>
                  </Row>
                </RadioGroup>
              </Form.Item>
            );

          return null;
        }}
      </HiddenFormItem>

      <Form.Item name={['currentExcesses', 'comments']} label="Comments">
        <Input.TextArea size="large" autoSize={{ minRows: 4 }} placeholder="Add a comment..." />
      </Form.Item>
    </>
  );
};

const Colors = {
  Navy: {
    normal: '#00205B',
    light: '#DDE7EC',
  },
  Silver: {
    normal: '#A7A9AC',
  },
  PaperiPaper: {
    100: '#F1F1F5',
    75: '#F5F5F8',
    50: '#F9F9FB',
    25: '#FCFCFD',
    0: '#FFFFFF',
  },
  Greys: {
    100: '#000000',
    75: '#404040',
    50: '#939598',
    25: '#E6E7E8',
  },
  Green: '#00BF4F',
  WarningRed: '#FF6900',
};

export const Palette = {
  branding: {
    primary: Colors.Navy,
    secondary: Colors.Silver,
  },
  paper: Colors.PaperiPaper,
  greys: Colors.Greys,
  success: Colors.Green,
  danger: Colors.WarningRed,
};

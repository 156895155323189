import React, { useContext } from 'react';
import { ThemeContext, ThemeProvider as TP } from './styledComponents';
import { Palette } from './colors';
import { Size } from './sizes';

const theme = {
  palette: Palette,
  size: Size,
};

export const ThemeProvider = ({ children }) => <TP theme={theme}>{children}</TP>;

export const useTheme = () => useContext(ThemeContext);
